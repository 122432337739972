import { db } from "../firebase.js";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";

const getLogging = async () => {
  const reference = collection(db, "logging");

  return await getDocs(reference).then((data) => {
    return data.docs.map((docSnap) => ({
      ...docSnap.data(),
      id: docSnap.id,
      parsedData: moment(docSnap.data().dateTime, "DD-MM-YYYY HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    }));
  });
};

const getPromptExample = async (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(body),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_API_URL + "/dgtlassist/getAssistantPrompt",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export { getLogging, getPromptExample };
