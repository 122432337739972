import React, { setState } from "react";
import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  where,
  query,
} from "firebase/firestore";

const updateCompanyIntentFields = async (company) => {
  var currentCompany = await getApiCompany(company.id);
  company.secrets = currentCompany.secrets;
  const docRef = doc(db, "companies", company.id);
  return await updateDoc(docRef, company);
};

const updateCompleteCompany = async (company) => {
  const docRef = doc(db, "companies", company.id);
  return await updateDoc(docRef, company);
};

const getApiCompany = async (id) => {
  const docRef = doc(db, "companies", id);
  return getDoc(docRef).then((data) => {
    return { ...data.data(), id: data.id };
  });
};

const getApiCompaniesForUser = async (user) => {
  const reference = collection(db, "companies");
  const q = query(reference, where("id", "==", user.companyId));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const getApiCompanies = async () => {
  const reference = collection(db, "companies");

  return await getDocs(reference).then((data) => {
    return data.docs.map((docSnap) => ({
      ...docSnap.data(),
      id: docSnap.id,
      name: docSnap.data().companyName,
    }));
  });
};
const deleteCompany = async (company) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(company),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/company/deleteCompany",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const createCompany = async (company) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(company),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/company/createCompany",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const updateCompany = async (company) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(company),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/company/updateCompany",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const uploadCompanyDataSourceFile = async (file) => {
  const requestOptions = {
    method: "POST",
    headers: { ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I" },
    body: file,
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/company/uploadCompanyDataSourceFile",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const deleteCompanyDatasource = async (source) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(source),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/company/deleteCompanyDatasource",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const deleteCompanyDatasourceCollection = async (source) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(source),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/company/deleteCompanyDatasourceCollection",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export {
  uploadCompanyDataSourceFile,
  deleteCompanyDatasource,
  deleteCompanyDatasourceCollection,
  getApiCompaniesForUser,
  updateCompanyIntentFields,
  getApiCompany,
  getApiCompanies,
  deleteCompany,
  createCompany,
  updateCompany,
  updateCompleteCompany,
};
