import integrationViewModel from "./integrationViewModel";

const ACTIONS = {
  UPDATE_WEBHOOK: "UPDATE_WEBHOOK",
  UPDATE_NAME: "UPDATE_NAME",
  UPDATE_INTEGRATION: "UPDATE_INTEGRATION",
  UPDATE_URL: "UPDATE_URL",
  CLEAR_REQUEST_STEP: "CLEAR_REQUEST_STEP",
  UPDATE_REQUEST_TYPE: "UPDATE_REQUEST_TYPE",
  UPDATE_INPUT_WEBHOOK: "UPDATE_INPUT_WEBHOOK",
  UPDATE_INVOKE_WEBHOOK: "UPDATE_INVOKE_WEBHOOK",
  UPDATE_SETTINGS_ORDERNUMBER_REQUIRED: "UPDATE_SETTINGS_ORDERNUMBER_REQUIRED",
  UPDATE_SETTINGS_PHONENUMBER_REQUIRED: "UPDATE_SETTINGS_PHONENUMBER_REQUIRED",
  UPDATE_SETTINGS_LOGGIN_ACTIVE: "UPDATE_SETTINGS_LOGGIN_ACTIVE",
  UPDATE_SETTINGS_IS_TOKEN_REFRESH_WEBHOOK:
    "UPDATE_SETTINGS_IS_TOKEN_REFRESH_WEBHOOK",
  UPDATE_SETTINGS_IS_CREATE_TICKET_WEBHOOK:
    "UPDATE_SETTINGS_IS_CREATE_TICKET_WEBHOOK",
  UPDATE_SETTINGS_IS_START_STEP: "UPDATE_SETTINGS_IS_START_STEP",
  UPDATE_SETTINGS_FORM_URL_ENCODED: "UPDATE_SETTINGS_FORM_URL_ENCODED",
  UPDATE_QUERYPARAMS: "UPDATE_QUERYPARAMS",
  UPDATE_HEADERS: "UPDATE_HEADERS",
  UPDATE_RESPONSES: "UPDATE_RESPONSES",
  UPDATE_TEST_INPUT_FIELDS: "UPDATE_TEST_INPUT_FIELDS",
  UPDATE_BODY: "UPDATE_BODY",
  UPDATE_AUTHENTICATION_TYPE: "UPDATE_AUTHENTICATION_TYPE",
  UPDATE_AUTHENTICATION_BASIC_USERNAME: "UPDATE_AUTHENTICATION_BASIC_USERNAME",
  UPDATE_AUTHENTICATION_BASIC_PASSWORD: "UPDATE_AUTHENTICATION_BASIC_PASSWORD",
  UPDATE_AUTHENTICATION_BEARER_TOKEN: "UPDATE_AUTHENTICATION_BEARER_TOKEN",
  UPDATE_WEBHOOK_FAILED_RESPONSE: "UPDATE_WEBHOOK_FAILED_RESPONSE",
  UPDATE_WEBHOOK_FAILED_RESPONSE_EVENT: "UPDATE_WEBHOOK_FAILED_RESPONSE_EVENT",
  UPDATE_WEBHOOK_FAILED_RESPONSE_DATA: "UPDATE_WEBHOOK_FAILED_RESPONSE_DATA",
  UPDATE_WEBHOOK_FAILED_PARAMS: "UPDATE_WEBHOOK_FAILED_PARAMS",
  UPDATE_WEBHOOK_FALLBACK_RESPONSE: "UPDATE_WEBHOOK_FALLBACK_RESPONSE",
  UPDATE_WEBHOOK_FALLBACK_RESPONSE_EVENT:
    "UPDATE_WEBHOOK_FALLBACK_RESPONSE_EVENT",
  UPDATE_WEBHOOK_FALLBACK_RESPONSE_DATA:
    "UPDATE_WEBHOOK_FALLBACK_RESPONSE_DATA",
  UPDATE_WEBHOOK_FALLBACK_PARAMS: "UPDATE_WEBHOOK_FALLBACK_PARAMS",
  UPDATE_WEBHOOK_MAPPING_RESPONSE: "UPDATE_WEBHOOK_MAPPING_RESPONSE",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_EVENT:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_EVENT",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_DATA: "UPDATE_WEBHOOK_MAPPING_RESPONSE_DATA",
  UPDATE_WEBHOOK_GPT_BODY: "UPDATE_WEBHOOK_GPT_BODY",
  UPDATE_WEBHOOK_GPT_BODY_MODEL: "UPDATE_WEBHOOK_GPT_BODY_MODEL",
  UPDATE_WEBHOOK_GPT_BODY_INPUT_VARS: "UPDATE_WEBHOOK_GPT_BODY_INPUT_VARS",
  UPDATE_WEBHOOK_GPT_RESPONSE: "UPDATE_WEBHOOK_GPT_RESPONSE",
  UPDATE_WEBHOOK_GPT_RESPONSE_MODEL: "UPDATE_WEBHOOK_GPT_RESPONSE_MODEL",
  UPDATE_WEBHOOK_GPT_RESPONSE_INPUT_VARS:
    "UPDATE_WEBHOOK_GPT_RESPONSE_INPUT_VARS",
  UPDATE_WEBHOOK_GPT_RESPONSE_KEY: "UPDATE_WEBHOOK_GPT_RESPONSE_KEY",
  UPDATE_WEBHOOK_MAPPING_TRIGGER_STEP: "UPDATE_WEBHOOK_MAPPING_TRIGGER_STEP",
  UPDATE_WEBHOOK_MAPPING_SEQUENCE: "UPDATE_WEBHOOK_MAPPING_SEQUENCE",
  UPDATE_WEBHOOK_MAPPING_PARAMS: "UPDATE_WEBHOOK_MAPPING_PARAMS",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_CONDITION:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_CONDITION",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_OPERATOR:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_OPERATOR",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_CONDITION_ROW:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_CONDITION_ROW",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_CONDITION_ROW:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_CONDITION_ROW",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_ROW:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_ROW",
  UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_ROW:
    "UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_ROW",
  UPDATE_FALLBACK_DELETE_ROW: "UPDATE_FALLBACK_DELETE_ROW",
};

const IntegrationReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_WEBHOOK:
      return updateWebhook(state, action);
    case ACTIONS.UPDATE_INTEGRATION:
      return updateIntegration(state, action);
    case ACTIONS.UPDATE_NAME:
      return updateName(state, action);
    case ACTIONS.UPDATE_URL:
      return updateUrl(state, action);
    case ACTIONS.UPDATE_REQUEST_TYPE:
      return updateRequestType(state, action);
    case ACTIONS.CLEAR_REQUEST_STEP:
      return clearRequestStep(state, action);
    case ACTIONS.UPDATE_INPUT_WEBHOOK:
      return updateInputWebhook(state, action);
    case ACTIONS.UPDATE_INVOKE_WEBHOOK:
      return updateInvokeWebhook(state, action);
    case ACTIONS.UPDATE_SETTINGS_ORDERNUMBER_REQUIRED:
      return updateSettingsOrdernumberRequired(state, action);
    case ACTIONS.UPDATE_SETTINGS_PHONENUMBER_REQUIRED:
      return updateSettingsPhonenumberRequired(state, action);
    case ACTIONS.UPDATE_SETTINGS_LOGGIN_ACTIVE:
      return updateSettingsLoggingActive(state, action);
    case ACTIONS.UPDATE_SETTINGS_IS_TOKEN_REFRESH_WEBHOOK:
      return updateSettingsIsTokenRefreshWebhook(state, action);
    case ACTIONS.UPDATE_SETTINGS_IS_CREATE_TICKET_WEBHOOK:
      return updateSettingsIsCreateTicketWebhook(state, action);
    case ACTIONS.UPDATE_SETTINGS_IS_START_STEP:
      return updateSettingsIsStartStep(state, action);
    case ACTIONS.UPDATE_SETTINGS_FORM_URL_ENCODED:
      return updateSettingsFormUrlEncoded(state, action);
    case ACTIONS.UPDATE_QUERYPARAMS:
      return updateQueryParams(state, action);
    case ACTIONS.UPDATE_HEADERS:
      return updateHeaders(state, action);
    case ACTIONS.UPDATE_RESPONSES:
      return updateResponses(state, action);
    case ACTIONS.UPDATE_TEST_INPUT_FIELDS:
      return updateTestInputFields(state, action);
    case ACTIONS.UPDATE_BODY:
      return updateBody(state, action);
    case ACTIONS.UPDATE_AUTHENTICATION_TYPE:
      return updateAuthenticationType(state, action);
    case ACTIONS.UPDATE_AUTHENTICATION_BASIC_USERNAME:
      return updateAuthenticationBasicUsername(state, action);
    case ACTIONS.UPDATE_AUTHENTICATION_BASIC_PASSWORD:
      return updateAuthenticationBasicPassword(state, action);
    case ACTIONS.UPDATE_AUTHENTICATION_BEARER_TOKEN:
      return updateAuthenticationBearerToken(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FAILED_RESPONSE:
      return updateWebhookFailedResponse(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FAILED_RESPONSE_EVENT:
      return updateWebhookFailedResponseEvent(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FAILED_RESPONSE_DATA:
      return updateWebhookFailedResponseData(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FAILED_PARAMS:
      return updateWebhookFailedParams(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FALLBACK_RESPONSE:
      return updateWebhookFallbackResponse(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FALLBACK_RESPONSE_EVENT:
      return updateWebhookFallbackResponseEvent(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FALLBACK_RESPONSE_DATA:
      return updateWebhookFallbackResponseData(state, action);
    case ACTIONS.UPDATE_WEBHOOK_FALLBACK_PARAMS:
      return updateWebhookFallbackParams(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_BODY:
      return updateGptBodyMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE:
      return updateGptResponseMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_BODY_MODEL:
      return updateGptBodyModelMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_BODY_INPUT_VARS:
      return updateGptBodyInputVarsMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE_MODEL:
      return updateGptResponseModelMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE_INPUT_VARS:
      return updateGptResponseInputVarsMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE_KEY:
      return updateGptResponseMappingKey(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE:
      return updateResponseMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_EVENT:
      return updateResponseMappingEvent(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_DATA:
      return updateResponseMappingData(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_TRIGGER_STEP:
      return updateResponseTriggerHookMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_SEQUENCE:
      return updateResponseSequence(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_PARAMS:
      return updateParamsMapping(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_CONDITION:
      return updateResponseMappingCondition(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_OPERATOR:
      return updateResponseMappingOperator(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_CONDITION_ROW:
      return updateResponseMappingAddConditionRow(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_CONDITION_ROW:
      return updateResponseMappingDeleteConditionRow(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_ROW:
      return updateResponseMappingAddRow(state, action);
    case ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_ROW:
      return updateResponseMappingDeleteRow(state, action);
    case ACTIONS.UPDATE_FALLBACK_DELETE_ROW:
      return updateFallbackDeleteRow(state, action);
    default:
      throw new Error();
  }
};

const updateWebhook = (state, action) => {
  return action.payload.webhook;
};
const updateName = (state, action) => {
  return { ...state, name: action.payload.name };
};
const updateIntegration = (state, action) => {
  return { ...state, integration: action.payload.integration };
};
const updateUrl = (state, action) => {
  return { ...state, url: action.payload.url };
};
const updateRequestType = (state, action) => {
  return { ...state, requestType: action.payload.requestType };
};
const clearRequestStep = (state, action) => {
  return integrationViewModel;
};
const updateInputWebhook = (state, action) => {
  return { ...state, inputWebhook: action.payload.inputWebhook };
};
const updateInvokeWebhook = (state, action) => {
  return { ...state, invokeWebhook: action.payload.invokeWebhook };
};
const updateSettingsOrdernumberRequired = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      requestOrdernumber: action.payload.ordernumberRequired,
    },
  };
};
const updateSettingsPhonenumberRequired = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      requestPhonenumber: action.payload.phonenumberRequired,
    },
  };
};
const updateSettingsLoggingActive = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      loggingActive: action.payload.loggingActive,
    },
  };
};
const updateSettingsIsStartStep = (state, action) => {
  return {
    ...state,
    settings: { ...state.settings, isStartStep: action.payload.isStartStep },
  };
};
const updateSettingsFormUrlEncoded = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      formUrlEncoded: action.payload.formUrlEncoded,
    },
  };
};

const updateSettingsIsTokenRefreshWebhook = (state, action) => {
  var tempState = { ...state }; //[0].key
  tempState.responses = action.payload.isTokenRefreshWebhook
    ? [{ key: "TOKEN", value: "" }]
    : [
        { key: "", value: "" },
        { key: "", value: "" },
        { key: "", value: "" },
        { key: "", value: "" },
        { key: "", value: "" },
      ];
  tempState.settings.isTokenRefreshWebhook =
    action.payload.isTokenRefreshWebhook;
  if (action.payload.isTokenRefreshWebhook) {
    tempState.settings.isCreateTicketWebhook = false;
  }
  console.log(tempState.settings);
  return tempState;
};
const updateSettingsIsCreateTicketWebhook = (state, action) => {
  var tempState = { ...state }; //[0].key
  tempState.responses = action.payload.isCreateTicketWebhook
    ? [{ key: "TICKET_NUMBER", value: "" }]
    : [
        { key: "", value: "" },
        { key: "", value: "" },
        { key: "", value: "" },
        { key: "", value: "" },
        { key: "", value: "" },
      ];
  tempState.settings.isCreateTicketWebhook =
    action.payload.isCreateTicketWebhook;
  if (action.payload.isCreateTicketWebhook) {
    tempState.settings.isTokenRefreshWebhook = false;
  }
  console.log(tempState.settings);
  return tempState;
};

const updateQueryParams = (state, action) => {
  return { ...state, queryParams: action.payload.queryParams };
};
const updateHeaders = (state, action) => {
  return { ...state, headers: action.payload.headers };
};
const updateResponses = (state, action) => {
  return { ...state, responses: action.payload.responses };
};
const updateTestInputFields = (state, action) => {
  return { ...state, testInputFields: action.payload.testInputFields };
};
const updateBody = (state, action) => {
  return { ...state, body: action.payload.body };
};
const updateAuthenticationType = (state, action) => {
  return {
    ...state,
    authentication: { [action.payload.authenticationType]: {} },
  };
};
const updateAuthenticationBasicUsername = (state, action) => {
  return {
    ...state,
    authentication: {
      basic: {
        ...state.authentication.basic,
        username: action.payload.authenticationBasicUsername,
      },
    },
  };
};
const updateAuthenticationBasicPassword = (state, action) => {
  return {
    ...state,
    authentication: {
      basic: {
        ...state.authentication.basic,
        password: action.payload.authenticationBasicPassword,
      },
    },
  };
};
const updateAuthenticationBearerToken = (state, action) => {
  return {
    ...state,
    authentication: {
      bearer: { token: action.payload.authenticationBearerToken },
    },
  };
};

const updateWebhookFailedResponse = (state, action) => {
  const newData = { ...state };
  delete newData.failed.params;
  if (newData.failed.responses == null) {
    newData.failed.responses = {
      [action.payload.language]: {
        message: action.payload.response,
        event: "",
        data: "",
      },
    };
  }
  const isString = newData.failed.responses?.[action.payload.language];
  if (
    typeof isString === "string" ||
    isString instanceof String ||
    isString == undefined ||
    isString == null
  ) {
    newData.failed.responses[action.payload.language] = {
      message: action.payload.response,
      event: "",
      data: "",
    };
  } else {
    newData.failed.responses[action.payload.language].message =
      action.payload.response;
  }
  return newData;
};

const updateWebhookFailedResponseEvent = (state, action) => {
  const newData = { ...state };
  delete newData.failed.params;
  newData.failed.responses[action.payload.language].event =
    action.payload.response;
  return newData;
};
const updateWebhookFailedResponseData = (state, action) => {
  const newData = { ...state };
  delete newData.failed.params;
  newData.failed.responses[action.payload.language].data =
    action.payload.response;
  return newData;
};
const updateWebhookFailedParams = (state, action) => {
  if (action.payload.value == null) {
    const newData = { ...state };
    delete newData.failed.params[action.payload.param];
    return newData;
  } else {
    return {
      ...state,
      failed: {
        ...state.failed,
        params: {
          ...state.failed.params,
          [action.payload.param]: action.payload.value,
        },
      },
    };
  }
};

const updateWebhookFallbackResponse = (state, action) => {
  const newData = { ...state };
  delete newData.mappingFallbackResponseValues.params;
  if (newData.mappingFallbackResponseValues.responses == null) {
    newData.mappingFallbackResponseValues.responses = {
      [action.payload.language]: {
        message: action.payload.response,
        event: "",
        data: "",
      },
    };
  }
  const isString =
    newData.mappingFallbackResponseValues.responses?.[action.payload.language];
  if (
    typeof isString === "string" ||
    isString instanceof String ||
    isString == undefined ||
    isString == null
  ) {
    newData.mappingFallbackResponseValues.responses[action.payload.language] = {
      message: action.payload.response,
      event: "",
      data: "",
    };
  } else {
    newData.mappingFallbackResponseValues.responses[
      action.payload.language
    ].message = action.payload.response;
  }
  return newData;
};
const updateWebhookFallbackResponseEvent = (state, action) => {
  const newData = { ...state };
  delete newData.mappingFallbackResponseValues.params;
  newData.mappingFallbackResponseValues.responses[
    action.payload.language
  ].event = action.payload.response;
  return newData;
};
const updateWebhookFallbackResponseData = (state, action) => {
  const newData = { ...state };
  delete newData.mappingFallbackResponseValues.params;
  newData.mappingFallbackResponseValues.responses[
    action.payload.language
  ].data = action.payload.response;
  return newData;
};
const updateWebhookFallbackParams = (state, action) => {
  if (action.payload.value == null) {
    const newData = { ...state };
    delete newData.mappingFallbackResponseValues.params[action.payload.param];
    return newData;
  } else {
    return {
      ...state,
      mappingFallbackResponseValues: {
        ...state.mappingFallbackResponseValues,
        params: {
          ...state.mappingFallbackResponseValues.params,
          [action.payload.param]: action.payload.value,
        },
      },
    };
  }
};
const updateGptBodyModelMapping = (state, action) => {
  const newData = { ...state };
  newData.gptModelBody = action.payload.response;
  return newData;
};
const updateGptResponseModelMapping = (state, action) => {
  const newData = { ...state };
  newData.gptResponseModelMapping = action.payload.response;
  return newData;
};
const updateGptBodyInputVarsMapping = (state, action) => {
  const newData = { ...state };
  newData.gptInputVarsBody = action.payload.response;
  return newData;
};
const updateGptResponseInputVarsMapping = (state, action) => {
  const newData = { ...state };
  newData.gptResponseInputVarsMapping = action.payload.response;
  return newData;
};
const updateGptBodyMapping = (state, action) => {
  const newData = { ...state };
  newData.gptBody = action.payload.response;
  return newData;
};
const updateGptResponseMapping = (state, action) => {
  const newData = { ...state };
  newData.gptResponseMapping = action.payload.response;
  return newData;
};
const updateGptResponseMappingKey = (state, action) => {
  const newData = { ...state };
  newData.gptResponseMappingKey = action.payload.response.toUpperCase();
  return newData;
};
const updateResponseMapping = (state, action) => {
  const newData = { ...state };
  delete newData.agentResponseMapping[action.payload.index].then.params;
  const isString =
    newData.agentResponseMapping[action.payload.index].then.responses[
      action.payload.language
    ];

  if (
    typeof isString === "string" ||
    isString instanceof String ||
    isString == undefined ||
    isString == null
  ) {
    newData.agentResponseMapping[action.payload.index].then.responses[
      action.payload.language
    ] = { message: "", event: "", data: "" };
  }
  console.log("sdfsdf", action.payload.response);
  if (action.payload.response == null || action.payload.response == undefined) {
    action.payload.response = "";
  }
  newData.agentResponseMapping[action.payload.index].then.responses[
    action.payload.language
  ].message = action.payload.response;
  return newData;
};

const updateResponseMappingEvent = (state, action) => {
  const newData = { ...state };
  delete newData.agentResponseMapping[action.payload.index].then.params;
  newData.agentResponseMapping[action.payload.index].then.responses[
    action.payload.language
  ].event = action.payload.response;
  return newData;
};

const updateResponseMappingData = (state, action) => {
  const newData = { ...state };
  newData.agentResponseMapping[action.payload.index].then.responses[
    action.payload.language
  ].data = action.payload.response;
  return newData;
};
const updateResponseTriggerHookMapping = (state, action) => {
  const newData = { ...state };
  delete newData.agentResponseMapping[action.payload.index].then.params;
  newData.agentResponseMapping[action.payload.index].then.triggerStep =
    action.payload.triggerStep;
  return newData;
};

const updateResponseSequence = (state, action) => {
  const newData = { ...state };
  newData.agentResponseMapping[action.payload.index].sequence =
    action.payload.sequence;
  return newData;
};

const updateParamsMapping = (state, action) => {
  if (action.payload.value == null) {
    const newData = { ...state };
    delete newData.agentResponseMapping[action.payload.index].then.params[
      action.payload.param
    ];
    return newData;
  } else {
    const newData = { ...state };
    newData.agentResponseMapping[action.payload.index].then.params[
      action.payload.param
    ] = action.payload.value;
    return newData;
  }
};

const updateResponseMappingCondition = (state, action) => {
  var newData = { ...state };
  newData.agentResponseMapping[action.payload.responseIndex].statements[
    action.payload.statementIndex
  ][action.payload.key] = action.payload.value;
  return newData;
};
const updateResponseMappingOperator = (state, action) => {
  const newData = { ...state };
  newData.agentResponseMapping[action.payload.index].operator =
    action.payload.value;
  return newData;
};
const updateResponseMappingAddConditionRow = (state, action) => {
  const newData = { ...state };
  newData.agentResponseMapping[action.payload.responseIndex].statements.push({
    inOperator: "",
    left: "",
    operator: "",
    right: "",
    outOperator: "",
  });
  return newData;
};

const updateResponseMappingDeleteConditionRow = (state, action) => {
  const newData = { ...state };
  newData.agentResponseMapping[action.payload.responseIndex].statements.splice(
    action.payload.statementIndex,
    1
  );
  if (
    newData.agentResponseMapping[action.payload.responseIndex].statements
      .length == 0
  ) {
    newData.agentResponseMapping[action.payload.responseIndex].statements.push({
      inOperator: "",
      left: "",
      operator: "",
      right: "",
      outOperator: "",
    });
  }
  return newData;
};

const updateResponseMappingAddRow = (state, action) => {
  const newData = { ...state };
  var seq = action.payload.sequence;

  if (seq.toString() == "NaN" || seq == NaN || seq == null || seq == null) {
    seq = 99;
  }
  newData.agentResponseMapping.push({
    operator: "",
    statements: [
      {
        inOperator: "",
        left: "",
        operator: "",
        right: "",
        outOperator: "",
      },
    ],
    sequence: seq,
    then: {
      params: {},
      responses: {},
    },
  });
  return newData;
};

const updateResponseMappingDeleteRow = (state, action) => {
  const newData = { ...state };
  newData.agentResponseMapping.splice(action.payload.responseIndex, 1);
  if (newData.agentResponseMapping.length == 0) {
    newData.agentResponseMapping.push({
      operator: "",
      statements: [
        {
          inOperator: "",
          left: "",
          operator: "",
          right: "",
          outOperator: "",
        },
      ],
      then: {
        params: {},
        responses: {},
      },
    });
  }
  return newData;
};

const updateFallbackDeleteRow = (state, action) => {
  return {
    ...state,
    fallbacks: state.fallbacks.filter(
      (element, index) => index != action.payload.rowIndex
    ),
  };
};

export { ACTIONS, IntegrationReducer };
