import React, { useState, useEffect, useMemo, useRef, useReducer } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoAddCircleSharp, IoClose } from "react-icons/io5";
import { BiPencil } from "react-icons/bi";
import { AiFillMinusCircle } from "react-icons/ai";
import { GrStatusUnknown } from "react-icons/gr";

import styles from "styles/admin/integration/integrationConfigurator.module.css";
import DictRow from "./dictRow";
import { query } from "firebase/firestore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { IoMdNotifications } from "react-icons/io";

import JSONEditorInstance from "./jsonEditorInstance";
import Deviation from "./deviation";
import {
  IntegrationGeneralReducer,
  ACTIONS,
} from "./integrationGeneralReducer";
import {
  IntegrationNewIntegrationReducer,
  NEWACTIONS,
} from "./integrationNewIntegrationReducer";
import integrationGeneralViewModel from "./integrationGeneralViewModel";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../../contexts/AuthContext";
import { toCamelCaseFormat } from "global/globalFunctions";
import { testIntegration } from "services/webhookService";
import Loading from "components/global/loading";
import integrationNewIntegrationViewModel from "./integrationNewIntegrationViewModel";
import {
  getIntegration,
  updateIntegrationDb,
  addIntegrationDb,
  getIntegrationsForCompany,
} from "../../../services/integrationService";
import {
  updateCompanyIntentFields,
  updateCompleteCompany,
} from "services/companyService";
import { getApiAgentsByCompany } from "services/agentService";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import {
  getApiCompanies,
  getApiCompany,
  updateCompany,
} from "services/companyService";

const IntegrationConfigurator = ({
  integration,
  company,
  updateSelectedIntegration,
  updateSelectedIntegrationHook,
  catalog,
}) => {
  const {
    setIndicatorState,
    resetIndicatorState,
    setInfoMessage,
    loadingState,
  } = useProgressIndicatorContext();
  const { currentDbUser } = useAuth();
  const [integrationState, dispatch] = useReducer(
    IntegrationGeneralReducer,
    integrationGeneralViewModel
  );
  const [newIntegrationState, newDispatch] = useReducer(
    IntegrationNewIntegrationReducer,
    integrationNewIntegrationViewModel
  );
  const [selectedLanguageOut, setSelectedLanguageOut] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState("NL");
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (!currentDbUser) return;

    const getCompanies = async () => {
      if (currentDbUser.isAdmin) {
        var retrievedCompanies = await getApiCompanies();
        setCompanies(retrievedCompanies);
        getAndSetCompanyFromLocalStorage(retrievedCompanies);
      } else {
        var retrievedCompanies = await getApiCompany(currentDbUser.companyId);
        setCompanies([...retrievedCompanies]);
        getAndSetCompanyFromLocalStorage([...retrievedCompanies]);
      }
    };
    getCompanies();
  }, [currentDbUser]);

  const getAndSetCompanyFromLocalStorage = (retrievedCompanies) => {
    const companyName = localStorage.getItem("selectedCompany");
    var selectCompany = retrievedCompanies.filter(
      (x) => x.name == companyName
    )[0];
    if (selectCompany != null) {
      setCopyToCompany(selectCompany);
    } else {
      setCopyToCompany(retrievedCompanies[0]);
    }
  };

  const [
    disableIntegrationNameIntegration,
    setDisableIntegrationNameIntegration,
  ] = useState(false);
  const [newWebhookName, setNewWebhookName] = React.useState("");
  const [isNew, setIsNew] = React.useState(false);
  const didMount = useRef(false);
  const [requiredField, setRequiredField] = useState({
    name: "",
    type: "",
    refreshHook: "",
    refreshTimeInMinutes: "",
  });

  const [integrationRequiredField, setIntegrationRequiredField] = useState({
    name: "",
    type: "",
  });

  useEffect(() => {
    if (company == null || company.integrations == null) return;

    getOrSetIntegration(integration);
  }, [integration, company]);

  useEffect(() => {
    console.log(integrationState);
  }, [integrationState]);

  const getOrSetIntegration = (integration) => {
    var integrationObject;
    if (integration.integration == "") {
      integrationObject = integrationGeneralViewModel;
      updateIntegration(integrationObject);
      setIsNew(true);
    } else {
      integrationObject = company.integrations[integration.integration];
      updateIntegration(integrationObject);
      setIsNew(false);
    }
    if (integration.integrationHook != "") {
      var integrationObjectHook =
        company.integrations[integration.integration].hooks[
          integration.integrationHook
        ];
      newUpdateIntegration(integrationObjectHook);
    } else {
      var integrationObjectHook = integrationNewIntegrationViewModel;
      newUpdateIntegration(integrationObjectHook);
    }
    setRequiredField({
      name: "",
      type: "",
      refreshHook: "",
      refreshTimeInMinutes: "",
    });

    setIntegrationRequiredField({
      name: "",
      type: "",
    });
  };

  const [integrationNameOut, setIntegrationNameOut] = React.useState(false);
  const toggleIntegrationName = () => {
    if (isNew) {
      setIntegrationNameOut(!integrationNameOut);
    }
  };

  const [
    integrationRequiredFieldSelectionOut,
    setIntegrationRequiredFieldSelectionOut,
  ] = React.useState(false);
  const toggleIntegrationRequiredFieldSelection = () => {
    setIntegrationRequiredFieldSelectionOut(
      !integrationRequiredFieldSelectionOut
    );
  };

  const [integrationRequiredFieldTypeOut, setIntegrationRequiredFieldTypeOut] =
    React.useState(false);
  const toggleIntegrationRequiredFieldType = () => {
    setIntegrationRequiredFieldTypeOut(!integrationRequiredFieldTypeOut);
  };

  const [requiredFieldSelectionOut, setRequiredFieldSelectionOut] =
    React.useState(false);
  const toggleRequiredFieldSelection = () => {
    setRequiredFieldSelectionOut(!requiredFieldSelectionOut);
  };

  const [requiredFieldTypeOut, setRequiredFieldTypeOut] = React.useState(false);
  const toggleRequiredFieldType = () => {
    setRequiredFieldTypeOut(!requiredFieldTypeOut);
  };

  const [requiredFieldWebhookOut, setRequiredFieldWebhookOut] =
    React.useState(false);
  const toggleRequiredFieldWebhookOut = () => {
    setRequiredFieldWebhookOut(!requiredFieldWebhookOut);
  };

  const updateSettingsIsCreateTicketWebhook = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_IS_CREATE_TICKET_WEBHOOK,
      payload: { isCreateTicketWebhook: value },
    });
    setIsSaved(false);
  };
  const updateSettingsIsPhoneQueueWebhook = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_IS_PHONE_QUEUE_WEBHOOK,
      payload: { isPhoneQueueWebhook: value },
    });
    setIsSaved(false);
  };

  const [isSaved, setIsSaved] = React.useState(true);

  const updateIntegration = (value) =>
    dispatch({
      type: ACTIONS.UPDATE_INTEGRATION,
      payload: { integration: value },
    });
  const updateName = (value) =>
    dispatch({ type: ACTIONS.UPDATE_NAME, payload: { name: value } });
  const updateDescription = (value) =>
    dispatch({
      type: ACTIONS.UPDATE_DESCRIPTION,
      payload: { description: value },
    });
  const updateRequiredFields = (value) =>
    dispatch({
      type: ACTIONS.UPDATE_REQUIRED_FIELDS,
      payload: { field: value },
    });
  const deleteRequiredFields = (value) =>
    dispatch({
      type: ACTIONS.DELETE_REQUIRED_FIELDS,
      payload: { field: value },
    });
  const updateImage = (e) => {
    var file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      dispatch({ type: ACTIONS.UPDATE_ICON, payload: { icon: reader.result } });
    };
    reader.readAsDataURL(file);

    // const reader = new FileReader();
    //     await new Promise(resolve => {
    //     reader.onload = ev => {
    //     dispatch({type:ACTIONS.UPDATE_IMAGE, payload: {image: ev.target.result}});
    //     resolve(ev.target.result)
    //     }
    //     reader.readAsDataURL(e.target.files[0])

    //})
  };
  const toggleSelectedLanguage = () => {
    setSelectedLanguageOut(!selectedLanguageOut);
  };

  const newUpdateIntegration = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_INTEGRATION,
      payload: { integration: value },
    });
  const newUpdateName = (value) => {
    if (
      Object.keys(company.integrations[integrationState.name].hooks).filter(
        (x) => x == value
      ).length == 0
    ) {
      newDispatch({ type: NEWACTIONS.UPDATE_NAME, payload: { name: value } });
    }
  };

  const newUpdateDescription = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_DESCRIPTION,
      payload: { description: value },
    });
  const newUpdateRequiredFields = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_REQUIRED_FIELDS,
      payload: { field: value },
    });
  const newDeleteRequiredFields = (value) =>
    newDispatch({
      type: NEWACTIONS.DELETE_REQUIRED_FIELDS,
      payload: { field: value },
    });
  const newUpdateSettingsOrdernumberRequired = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_SETTINGS_ORDERNUMBER_REQUIRED,
      payload: { ordernumberRequired: value },
    });
  const newUpdateSettingsPhonenumberRequired = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_SETTINGS_PHONENUMBER_REQUIRED,
      payload: { phonenumberRequired: value },
    });
  const newUpdateSettingsIsTokenRefreshHook = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_SETTINGS_IS_TOKEN_REFRESH_INTEGRATION,
      payload: { isTokenRefreshIntegration: value },
    });
  const newUpdateSettingsIsCreateTicketHook = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_SETTINGS_IS_CREATE_TICKET_INTEGRATION,
      payload: { isCreateTicketIntegration: value },
    });
  const newUpdateSettingsIsPhoneQueueHook = (value) =>
    newDispatch({
      type: NEWACTIONS.UPDATE_SETTINGS_IS_PHONE_QUEUE_INTEGRATION,
      payload: { isPhoneQueueIntegration: value },
    });

  const updateNameSetting = (name) => {
    console.log(name);
    //getOrSetIntegration({integration: name});
    updateSelectedIntegration(name);
  };

  const flexContainer = {
    display: "flex",
    flexDirection: "row",
  };

  const save = () => {
    if (company.name == "Integration catalog") {
      if (integration.integration == "") {
        var newIntegration = {
          name: integrationState.name,
          catalogId: catalog.id,
          description: integrationState.description,
          icon: integrationState.icon,
          requiredFields: {},
          hooks: {},
        };
        addIntegrationDb(newIntegration);
        updateSelectedIntegration(integrationState.name);
      } else {
        updateIntegrationDb(integrationState);
      }
    } else {
      if (integration.integration == "") {
        company.integrations[integrationState.name] = {
          name: integrationState.name,
          catalogId: catalog.id,
          description: integrationState.description,
          icon: integrationState.icon,
          requiredFields: {},
          hooks: {},
        };
        updateCompanyIntentFields(company);
        updateSelectedIntegration(integrationState.name);
      } else {
        company.integrations[integrationState.name] = integrationState;
        updateCompanyIntentFields(company);
      }
    }
  };
  const saveIntegration = () => {
    if (company.name == "Integration catalog") {
      integrationState.hooks[newIntegrationState.name] = newIntegrationState;
      console.log(integrationState);
      updateIntegrationDb(integrationState);
      updateSelectedIntegrationHook(newIntegrationState.name);
    } else {
      company.integrations[integrationState.name].hooks[
        newIntegrationState.name
      ] = newIntegrationState;
      if (company.installedIntegrations[integrationState.name] == null) {
        company.installedIntegrations[integrationState.name] = [];
      }
      company.installedIntegrations[integrationState.name] = [
        ...company.installedIntegrations[integrationState.name].filter(
          (x) => x != newIntegrationState.name
        ),
        newIntegrationState.name,
      ];
      updateCompanyIntentFields(company);
      updateSelectedIntegrationHook(newIntegrationState.name);
    }
  };

  const [requiredFields, setRequiredFields] = React.useState([]);
  const [openTestIntegrationDialog, setOpenTestIntegrationDialog] =
    React.useState(false);

  const [openDeleteIntegrationDialog, setOpenDeleteIntegrationDialog] =
    React.useState(false);
  const [openCopyIntegrationDialog, setOpenCopyIntegrationDialog] =
    React.useState(false);

  const handleDeleteIntegration = () => {
    setOpenDeleteIntegrationDialog(false);

    delete company.integrations[integrationState.name].hooks[
      newIntegrationState.name
    ];

    console.log(company.integrations[integrationState.name].hooks);
    updateCompanyIntentFields(company);
    updateSelectedIntegration(integrationState.name);
  };

  const handleOpenTestIntegrationDialog = () => {
    setApiResponseMessage("");
    var requiredFieldsObj = [];
    var outputParams = [];

    Object.entries(
      company.integrations[integrationState.name].hooks[
        newIntegrationState.name
      ].steps
    ).forEach(([key, value]) => {
      value.exportParameters.map((param) => {
        if (outputParams.filter((x) => x == param.key).length == 0) {
          outputParams.push(param);
        }
      });
    });
    console.log(outputParams);
    Object.entries(
      company.integrations[integrationState.name].hooks[
        newIntegrationState.name
      ].steps
    ).forEach(([key, value]) => {
      value.testInputFields.map((param) => {
        if (
          requiredFieldsObj.filter((x) => x.key == param.key).length == 0 &&
          outputParams.filter((x) => x == param.key).length == 0
        ) {
          requiredFieldsObj.push({ key: param.key, value: "" });
        }
      });
    });
    setRequiredFields(requiredFieldsObj);
    setOpenTestIntegrationDialog(true);
  };
  const testFieldsFilled = () =>
    requiredFields.filter((x) => x.value == "").length == 0;
  const updateRequiredTestField = (key, value) => {
    setRequiredFields(
      requiredFields.map((x) =>
        x.key == key ? { key: x.key, value: value } : x
      )
    );
  };

  const handleOpenTestIntegrationDialogOk = () => {
    setOpenTestIntegrationDialog(false);
  };

  const addRequiredField = () => {
    if (requiredField.name != "") {
      updateRequiredFields(requiredField);
      setTimeout(() => {
        save();
      }, 500);
    }
  };
  const deleteRequiredField = () => {
    deleteRequiredFields(requiredField);
    setTimeout(() => {
      save();
      setRequiredField({
        name: "",
        type: "",
        refreshHook: "",
        refreshTimeInMinutes: "",
      });
    }, 500);
  };
  const selectRequiredField = (t) => {
    if (t == "") {
      setRequiredField({
        name: "",
        type: "",
        refreshHook: "",
        refreshTimeInMinutes: "",
      });
    } else {
      setRequiredField(
        company.integrations[integration.integration].requiredFields[t]
      );
    }
  };
  const updateRequiredFieldType = (t) => {
    setRequiredField({ ...requiredField, type: t });
  };
  const updateRequiredFieldName = (t) => {
    setRequiredField({ ...requiredField, name: t.toUpperCase() });
  };
  const updateRequiredFieldRefreshHook = (t) => {
    setRequiredField({ ...requiredField, refreshHook: t });
  };
  const updateRequiredFieldRefreshTime = (t) => {
    setRequiredField({ ...requiredField, refreshTimeInMinutes: t });
  };

  const addIntegrationRequiredField = () => {
    if (integrationRequiredField.name != "") {
      newUpdateRequiredFields(integrationRequiredField);
      setTimeout(() => {
        saveIntegration();
      }, 500);
    }
  };
  const deleteIntegrationRequiredField = () => {
    newDeleteRequiredFields(integrationRequiredField);
    setTimeout(() => {
      saveIntegration();
      setIntegrationRequiredField({
        name: "",
        type: "",
        refreshHook: "",
        refreshTimeInMinutes: "",
      });
    }, 500);
  };
  const selectIntegrationRequiredField = (t) => {
    if (t == "") {
      setIntegrationRequiredField({
        name: "",
        type: "",
        refreshHook: "",
        refreshTimeInMinutes: "",
      });
    } else {
      setIntegrationRequiredField(
        company.integrations[integration.integration].hooks[
          integration.integrationHook
        ].requiredFields[t]
      );
    }
  };
  const updateIntegrationRequiredFieldType = (t) => {
    setIntegrationRequiredField({ ...integrationRequiredField, type: t });
  };
  const updateIntegrationRequiredFieldName = (t) => {
    setIntegrationRequiredField({
      ...integrationRequiredField,
      name: t.toUpperCase(),
    });
  };

  const getIntegrationRefreshHooks = () => {
    if (integration.integration == "") {
      return;
    }
    var list = Object.entries(
      company.integrations[integration.integration].hooks
    )
      .filter((x) => x[1].settings.isTokenRefreshIntegration)
      .map((x) => ({ name: x[1].name }));
    return list.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const [
    openNewIntegrationNameConfigurator,
    setOpenNewIntegrationNameConfigurator,
  ] = React.useState(false);
  const handleClickOpenIntegrationName = () => {
    setOpenNewIntegrationNameConfigurator(true);
  };

  const handleClickCloseNewIntegrationNameOk = () => {
    setOpenNewIntegrationNameConfigurator(false);
    updateName(newWebhookName);
    setNewWebhookName("");
  };

  const handleClickCloseNewIntegrationNameCancel = () => {
    setOpenNewIntegrationNameConfigurator(false);
  };

  const [apiResponseMessage, setApiResponseMessage] = React.useState("");
  const doTestWebhook = async () => {
    setApiResponseMessage("");

    let object;
    if (company.name != "Integration catalog") {
      var agents = await getApiAgentsByCompany(company.id);
      if (agents.length == 0) return;

      object = {
        CompanyId: company.id,
        DialogflowAgentId: agents[0].dialogflowAgentId,
        Language: selectedLanguage,
        Webhook: `${integration.integration}|${integration.integrationHook}`,
        Parameters: requiredFields,
        ReturnJsonResponse: false,
      };
    } else {
      object = {
        CompanyId: "integrationCatalog",
        DialogflowAgentId: "integrationCatalog",
        Language: selectedLanguage,
        Webhook: `${integration.integration}|${integration.integrationHook}`,
        Parameters: requiredFields,
        ReturnJsonResponse: false,
      };
    }
    testIntegration(object).then((result) => {
      var message = result.fulfillment_response.messages[0].text.text[0];
      setApiResponseMessage(message);
    });
  };

  const [copyIntegrations, setCopyIntegrations] = useState([]);
  const [copyToCompany, setCopyToCompany] = useState();
  const [copyToCompanyName, setCopyToCompanyName] = useState();
  const [copyToCompanyObj, setCopyToCompanyObj] = useState();
  const [copyToIntegration, setCopyToIntegration] = useState();
  const integrationHasStartStep = () =>
    company?.integrations[integration.integration]?.hooks[
      integration.integrationHook
    ]?.settings.startStep != "";

  const copyCompanySelected = (e) => {
    setCopyToCompany(e.target.value);
    getIntegrations(e.target.value);
  };

  const copyIntegrationSelected = (e) => {
    setCopyToIntegration(e.target.value);
  };
  const getIntegrations = (companyId) => {
    getApiCompany(companyId).then((x) => {
      setCopyToCompanyObj(x);
      setCopyIntegrations(Object.keys(x.integrations));
    });
  };

  const handleClickCloseCopyIntegrationCancel = () => {
    setOpenCopyIntegrationDialog(false);
    setCopyToCompany("");
    setCopyToIntegration("");
    setCopyToCompanyName("");
  };
  const handleClickCloseCopyIntegrationOk = () => {
    setIndicatorState("Copying webhook");
    var integrationHookToCopy = {
      ...company.integrations[integration.integration].hooks[
        integration.integrationHook
      ],
    };
    if (integrationHookToCopy == null) {
      return;
    }
    integrationHookToCopy.name = copyToCompanyName;
    var copied = { ...copyToCompanyObj };
    copied.integrations[copyToIntegration].hooks[copyToCompanyName] =
      integrationHookToCopy;

    if (copied.installedIntegrations[copyToIntegration] == null) {
      copied.installedIntegrations[copyToIntegration] = [];
    }
    copied.installedIntegrations[copyToIntegration].push(copyToCompanyName);
    setCopyToCompanyObj(copied);
    console.log(copied);

    updateCompleteCompany(copied).then((x) => {
      setOpenCopyIntegrationDialog(false);
      setCopyToCompany("");
      setCopyToIntegration("");
      setCopyToCompanyName("");
      resetIndicatorState("Copying webhook successfull");
    });
  };
  return (
    <>
      {integrationState?.name == null ? (
        <Loading />
      ) : (
        <div className={`${styles.integrationConfigurator}`}>
          <div className={styles.integrationGeneral}>
            <div className={styles.integrationImageContainer}>
              <div className={styles.integrationImage}>
                <div className={styles.imageUpload}>
                  <label for="file-input">
                    <img src={integrationState.icon} />
                  </label>
                  <input onChange={updateImage} id="file-input" type="file" />
                </div>
              </div>
            </div>
            <div className={styles.integrationSettingsContainer}>
              <div className={styles.row}>
                <div
                  className={`${styles.requiredField} ${
                    integrationNameOut ? styles.requiredFieldOut : ""
                  } ${!isNew ? styles.dropDownDisabled : ""}`}
                  onClick={toggleIntegrationName}
                >
                  {integrationState.name}{" "}
                  <IoMdArrowDropdown className={styles.requiredFieldIcon} />
                  {integrationNameOut && (
                    <div className={styles.requiredFieldOptions}>
                      <div
                        onClick={() => handleClickOpenIntegrationName()}
                        className={styles.requiredFieldOptionsRow}
                      >
                        new
                      </div>
                      {Object.entries(company.integrations)
                        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                        .map((x) => (
                          <div
                            onClick={() => updateNameSetting(x[0])}
                            className={styles.requiredFieldOptionsRow}
                          >
                            {x[0]}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div
                  onClick={save}
                  className={`${styles.btnStyle} ${styles.saveBtn} ${
                    integrationState.name == "" ? styles.disableButton : ""
                  }`}
                >
                  SAVE
                </div>
              </div>
              <textarea
                onChange={(e) => updateDescription(e.target.value)}
                placeholder="Description"
                rows="5"
                className={styles.integrationInputBox}
                value={integrationState.description}
              ></textarea>
            </div>

            {integration.integration && (
              <>
                <div className={styles.integrationRequiredFieldsContainer}>
                  <div className={styles.row}>
                    <div
                      className={
                        styles.integrationRequiredFieldsContainerHeader
                      }
                    >
                      Required fields
                    </div>

                    <div
                      className={`${styles.requiredField} ${
                        requiredFieldSelectionOut ? styles.requiredFieldOut : ""
                      }`}
                      onClick={toggleRequiredFieldSelection}
                    >
                      {requiredField.name}{" "}
                      <IoMdArrowDropdown className={styles.requiredFieldIcon} />
                      {requiredFieldSelectionOut && (
                        <div className={styles.requiredFieldOptions}>
                          <div
                            onClick={() => selectRequiredField("")}
                            className={styles.requiredFieldOptionsRow}
                          >
                            new
                          </div>
                          {Object.entries(integrationState.requiredFields).map(
                            (x) => (
                              <div
                                onClick={() => selectRequiredField(x[0])}
                                className={styles.requiredFieldOptionsRow}
                              >
                                {x[0]}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      onClick={addRequiredField}
                      className={`${styles.btnStyle} ${styles.saveBtn} ${
                        requiredField.name == "" || requiredField.type == ""
                          ? styles.disableButton
                          : ""
                      }`}
                    >
                      Save
                    </div>
                    <div
                      onClick={deleteRequiredField}
                      className={`${styles.btnStyle} ${styles.saveBtn}`}
                    >
                      Delete
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div
                      className={
                        styles.integrationRequiredFieldsContainerHeader
                      }
                    >
                      Type:
                    </div>
                    <div
                      className={`${styles.requiredField} ${
                        requiredFieldTypeOut ? styles.requiredFieldOut : ""
                      }`}
                      onClick={toggleRequiredFieldType}
                    >
                      {requiredField.type}{" "}
                      <IoMdArrowDropdown className={styles.requiredFieldIcon} />
                      {requiredFieldTypeOut && (
                        <div className={styles.requiredFieldOptions}>
                          <div
                            onClick={() => updateRequiredFieldType("config")}
                            className={styles.requiredFieldOptionsRow}
                          >
                            CONFIG
                          </div>
                          <div
                            onClick={() => updateRequiredFieldType("secret")}
                            className={styles.requiredFieldOptionsRow}
                          >
                            SECRET
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className={
                        styles.integrationRequiredFieldsContainerHeader
                      }
                    >
                      Name:
                    </div>
                    <input
                      value={requiredField.name}
                      onChange={(e) => updateRequiredFieldName(e.target.value)}
                      type="text"
                      className={styles.integrationInputBox}
                      placeholder="Name"
                    />

                    {requiredField.type == "secret" && (
                      <>
                        <div
                          className={
                            styles.integrationRequiredFieldsContainerHeader
                          }
                        >
                          Refresh in min:
                        </div>
                        <input
                          value={requiredField.refreshTimeInMinutes}
                          onChange={(e) =>
                            updateRequiredFieldRefreshTime(e.target.value)
                          }
                          type="text"
                          className={styles.integrationInputBox}
                          placeholder="Refreshtime"
                        />

                        <div
                          className={
                            styles.integrationRequiredFieldsContainerHeader
                          }
                        >
                          Refresh hook:
                        </div>
                        <div
                          className={`${styles.requiredField} ${
                            requiredFieldWebhookOut
                              ? styles.requiredFieldOut
                              : ""
                          }`}
                          onClick={toggleRequiredFieldWebhookOut}
                        >
                          {requiredField.refreshHook}{" "}
                          <IoMdArrowDropdown
                            className={styles.requiredFieldIcon}
                          />
                          {requiredFieldWebhookOut && (
                            <div className={styles.requiredFieldOptions}>
                              {getIntegrationRefreshHooks().map((x) => (
                                <div
                                  onClick={() =>
                                    updateRequiredFieldRefreshHook(x.name)
                                  }
                                  className={styles.requiredFieldOptionsRow}
                                >
                                  {x.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {integration.integration != "" && (
                  <div className={styles.integrationNewContainer}>
                    <div className={styles.row}>
                      <div
                        className={
                          styles.integrationRequiredFieldsContainerHeader
                        }
                      >
                        New integration name:
                      </div>
                      <input
                        value={newIntegrationState.name}
                        onChange={(e) => newUpdateName(e.target.value)}
                        type="text"
                        className={styles.integrationInputBox}
                        placeholder="Integration name"
                      />
                      <div
                        onClick={saveIntegration}
                        className={`${styles.btnStyle} ${styles.saveBtn} ${
                          newIntegrationState.name == ""
                            ? styles.disableButton
                            : ""
                        }`}
                      >
                        SAVE
                      </div>
                      <div
                        onClick={handleOpenTestIntegrationDialog}
                        className={`${styles.btnStyle} ${styles.saveBtn}`}
                      >
                        TEST
                      </div>
                      <div
                        onClick={() => setOpenDeleteIntegrationDialog(true)}
                        className={`${styles.btnStyle} ${styles.saveBtn}`}
                      >
                        DELETE
                      </div>
                      <div
                        onClick={() => setOpenCopyIntegrationDialog(true)}
                        className={`${styles.btnStyle} ${styles.saveBtn}`}
                      >
                        COPY
                      </div>
                    </div>
                    <textarea
                      onChange={(e) => newUpdateDescription(e.target.value)}
                      placeholder="Description"
                      rows="1"
                      className={styles.integrationInputBox}
                      value={newIntegrationState.description}
                    ></textarea>

                    <div className={styles.row}>
                      <FormGroup style={flexContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                newUpdateSettingsOrdernumberRequired(
                                  e.target.checked
                                )
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={
                                newIntegrationState.settings.ordernumberRequired
                              }
                            />
                          }
                          label={
                            <Box component="div" fontSize={12}>
                              Ordernumber required
                            </Box>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                newUpdateSettingsPhonenumberRequired(
                                  e.target.checked
                                )
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={
                                newIntegrationState.settings.phonenumberRequired
                              }
                            />
                          }
                          label={
                            <Box component="div" fontSize={12}>
                              Phonenumber required
                            </Box>
                          }
                        />
                      </FormGroup>
                    </div>
                    <div className={styles.row}>
                      <FormGroup style={flexContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                newUpdateSettingsIsTokenRefreshHook(
                                  e.target.checked
                                )
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={
                                newIntegrationState.settings
                                  .isTokenRefreshIntegration
                              }
                            />
                          }
                          label={
                            <Box component="div" fontSize={12}>
                              Is token refresh integration
                            </Box>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                newUpdateSettingsIsCreateTicketHook(
                                  e.target.checked
                                )
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={
                                newIntegrationState.settings
                                  .isCreateTicketIntegration
                              }
                            />
                          }
                          label={
                            <Box component="div" fontSize={12}>
                              Is create ticket integration
                            </Box>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                newUpdateSettingsIsPhoneQueueHook(
                                  e.target.checked
                                )
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={
                                newIntegrationState.settings
                                  .isPhoneQueueIntegration
                              }
                            />
                          }
                          label={
                            <Box component="div" fontSize={12}>
                              Is phonequeue integration
                            </Box>
                          }
                        />
                      </FormGroup>
                    </div>
                    <div className={styles.row}>
                      <div
                        className={styles.integrationRequiredFieldsContainer}
                      >
                        <div className={styles.row}>
                          <div
                            className={
                              styles.integrationRequiredFieldsContainerHeader
                            }
                          >
                            Required fields
                          </div>

                          <div
                            className={`${styles.requiredField} ${
                              integrationRequiredFieldSelectionOut
                                ? styles.requiredFieldOut
                                : ""
                            }`}
                            onClick={toggleIntegrationRequiredFieldSelection}
                          >
                            {integrationRequiredField.name}{" "}
                            <IoMdArrowDropdown
                              className={styles.requiredFieldIcon}
                            />
                            {integrationRequiredFieldSelectionOut && (
                              <div className={styles.requiredFieldOptions}>
                                <div
                                  onClick={() =>
                                    selectIntegrationRequiredField("")
                                  }
                                  className={styles.requiredFieldOptionsRow}
                                >
                                  new
                                </div>
                                {Object.entries(
                                  newIntegrationState.requiredFields
                                ).map((x) => (
                                  <div
                                    onClick={() =>
                                      selectIntegrationRequiredField(x[0])
                                    }
                                    className={styles.requiredFieldOptionsRow}
                                  >
                                    {x[0]}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div
                            onClick={addIntegrationRequiredField}
                            className={`${styles.btnStyle} ${styles.saveBtn} ${
                              integrationRequiredField.name == "" ||
                              integrationRequiredField.type == ""
                                ? styles.disableButton
                                : ""
                            }`}
                          >
                            Save
                          </div>
                          <div
                            onClick={deleteIntegrationRequiredField}
                            className={`${styles.btnStyle} ${styles.saveBtn}`}
                          >
                            Delete
                          </div>
                        </div>
                        <div className={styles.row}>
                          <div
                            className={
                              styles.integrationRequiredFieldsContainerHeader
                            }
                          >
                            Type:
                          </div>
                          <div
                            className={`${styles.requiredField} ${
                              integrationRequiredFieldTypeOut
                                ? styles.requiredFieldOut
                                : ""
                            }`}
                            onClick={toggleIntegrationRequiredFieldType}
                          >
                            {integrationRequiredField.type}{" "}
                            <IoMdArrowDropdown
                              className={styles.requiredFieldIcon}
                            />
                            {integrationRequiredFieldTypeOut && (
                              <div className={styles.requiredFieldOptions}>
                                <div
                                  onClick={() =>
                                    updateIntegrationRequiredFieldType("config")
                                  }
                                  className={styles.requiredFieldOptionsRow}
                                >
                                  CONFIG
                                </div>
                                <div
                                  onClick={() =>
                                    updateIntegrationRequiredFieldType("secret")
                                  }
                                  className={styles.requiredFieldOptionsRow}
                                >
                                  SECRET
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            className={
                              styles.integrationRequiredFieldsContainerHeader
                            }
                          >
                            Name:
                          </div>
                          <input
                            value={integrationRequiredField.name}
                            onChange={(e) =>
                              updateIntegrationRequiredFieldName(e.target.value)
                            }
                            type="text"
                            className={styles.integrationInputBox}
                            placeholder="Name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <Dialog open={openTestIntegrationDialog} fullWidth={"lg"}>
        <DialogTitle>Test integration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Test the integration
            {!integrationHasStartStep() && (
              <strong className={styles.warningMessage}>
                Integration has no start step
              </strong>
            )}
          </DialogContentText>
          <div className={styles.conditionRowContainer}>
            <div className={styles.testIntegrationRow}>
              <div className={styles.columnSettings}>
                <div
                  className={`${styles.httpRequestType} ${styles.authType} `}
                  onClick={toggleSelectedLanguage}
                >
                  {selectedLanguage}{" "}
                  <IoMdArrowDropdown className={styles.httpRequestTypeIcon} />
                  {selectedLanguageOut && (
                    <div className={styles.httpRequestTypeOptions}>
                      <div
                        onClick={() => setSelectedLanguage("NL")}
                        className={styles.httpRequestTypeOptionsRow}
                      >
                        NL
                      </div>
                      <div
                        onClick={() => setSelectedLanguage("DE")}
                        className={styles.httpRequestTypeOptionsRow}
                      >
                        DE
                      </div>
                      <div
                        onClick={() => setSelectedLanguage("EN")}
                        className={styles.httpRequestTypeOptionsRow}
                      >
                        EN
                      </div>
                    </div>
                  )}
                </div>

                {requiredFields.map((x) => (
                  <TextField
                    className={styles.conditionInput}
                    margin="dense"
                    type="text"
                    id={x.key}
                    label={x.key}
                    onChange={(e) =>
                      updateRequiredTestField(x.key, e.target.value)
                    }
                    fullWidth
                    value={x.value}
                    variant="standard"
                  />
                ))}
              </div>
              <div className={styles.columnOutput}>{apiResponseMessage}</div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!testFieldsFilled() || !integrationHasStartStep()}
            onClick={doTestWebhook}
          >
            Test
          </Button>
          <Button onClick={handleOpenTestIntegrationDialogOk}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNewIntegrationNameConfigurator} fullWidth={"lg"}>
        <DialogTitle>New integration name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new integration name to the collection
          </DialogContentText>
          <div className={styles.conditionRowContainer}>
            <div className={styles.conditionRow}>
              <TextField
                className={styles.conditionInput}
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                onChange={(e) => setNewWebhookName(e.target.value)}
                value={newWebhookName}
                variant="standard"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseNewIntegrationNameCancel}>
            Cancel
          </Button>
          <Button onClick={handleClickCloseNewIntegrationNameOk}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteIntegrationDialog} fullWidth={"lg"}>
        <DialogTitle>Delete integration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete integration{" "}
            <strong>{newIntegrationState.name}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteIntegrationDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleDeleteIntegration}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCopyIntegrationDialog} fullWidth={"lg"}>
        <DialogTitle>Copy integration</DialogTitle>
        <DialogContent>
          <div className={styles.conditionRowContainer}>
            <div className={styles.conditionRow}>
              <strong>Company: </strong>
              <select
                onChange={(e) => copyCompanySelected(e)}
                value={copyToCompany}
              >
                <option></option>
                {companies?.map((s) => {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <hr />
            <div className={styles.conditionRow}>
              <strong>Integration: </strong>
              <select
                onChange={(e) => copyIntegrationSelected(e)}
                value={copyToIntegration}
              >
                <option></option>
                {copyIntegrations?.map((s) => {
                  return (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  );
                })}
              </select>
            </div>
            <hr />
            <div className={styles.conditionRow}>
              <strong>Name: </strong>
              <TextField
                className={styles.conditionInput}
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                onChange={(e) => setCopyToCompanyName(e.target.value)}
                value={copyToCompanyName}
                variant="standard"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseCopyIntegrationCancel}>
            Cancel
          </Button>
          <Button onClick={handleClickCloseCopyIntegrationOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
//     {/* <input disabled={!isNew} value={integrationState.name} onChange={(e) => updateName(e.target.value)} type="text" className={styles.urlBuilderInput} placeholder="name"/>

//         <div className={`${styles.httpRequestType} ${httpRequestTypeOut ? styles.httpRequestTypeOut : ''}`} onClick={toggleHttpRequestType}>
//             {integrationState.requestType} <IoMdArrowDropdown className={styles.httpRequestTypeIcon}/>

//             {httpRequestTypeOut &&(
//                 <div className={styles.httpRequestTypeOptions}>
//                     <div onClick={() => updateRequestType("GET")} className={styles.httpRequestTypeOptionsRow}>GET</div>
//                     <div onClick={() => updateRequestType("POST")} className={styles.httpRequestTypeOptionsRow}>POST</div>
//                 </div>
//             )}
//         </div>

//         <input value={integrationState.url} onChange={(e) => updateUrl(e.target.value)} type="text" className={`${styles.urlBuilderInput} ${styles.urlInput}`}/>
//         <div onClick={save} className={`${styles.btnStyle} ${integrationState.name == '' || integrationState.integration == '' ? styles.btnDisabled : ''} ${!isSaved ? styles.saveGlowing : ''}`}>SAVE</div>

//     </div>
// </div> */}

export default IntegrationConfigurator;
