import React, { useEffect, useState, useMemo } from "react";
//import styles from "styles/admin/assistants/assistants.module.css";
import styles2 from "./override.assistant.css";
import styles from "styles/admin/assistants/assistants.module.css";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import {
  getApiSquats,
  getApiSquatAssistants,
  updateApiAssistant,
  updateApiSquat,
  deleteApiAssistant,
  addNewApiSquat,
  addNewApiAssistant,
  deleteApiSquat,
  attachApiAssistant,
  getApiAssistant,
} from "services/squatService";
import { IoMdCopy } from "react-icons/io";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaStar } from "react-icons/fa";
import { IoSaveOutline } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { confirm } from "react-confirm-box";
import { SlArrowDown } from "react-icons/sl";
import { MdOutlineManageAccounts } from "react-icons/md";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import TimePicker from "react-time-picker";
import FormSelect from "components/global/formSelect";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Button } from "react-bootstrap";
import AssistantChatbox from "./assistantChatbox";
import { getApiCompanies, getApiCompany } from "services/companyService";
import { getPromptExample } from "services/loggingService";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
const Assistants = () => {
  const {
    setIndicatorState,
    resetIndicatorState,
    setInfoMessage,
    loadingState,
  } = useProgressIndicatorContext();

  const [squats, setSquats] = useState([]);
  const [squat, setSquat] = useState({});
  const [assistant, setAssistant] = useState();
  const [editorState, setEditorState] = useState();
  const [openNewAssistant, setOpenNewAssistant] = useState(false);
  const [openNewSquat, setOpenNewSquat] = useState(false);
  const [openNewTool, setOpenNewTool] = useState(false);
  const [openNewField, setOpenNewField] = useState(false);
  const [openSeePrompt, setOpenSeePrompt] = useState(false);

  const [name, setName] = useState("");
  const [tabState, setTabState] = useState("prompt");
  const [tool, setTool] = useState();
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
  };
  useEffect(() => {
    const getCompanies = async () => {
      var retrievedCompanies = await getApiCompanies();
      setCompanies(retrievedCompanies);
    };
    getCompanies();
  }, []);

  const getSquats = async (companyName) => {
    setSquats(await getApiSquats(companyName));
  };

  const filteredCompanies = useMemo(() => {
    // Create copy of item list
    var updatedList = [...companies];
    // Include all elements which includes the search query
    return (updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
    }));
  }, [companies, searchQuery]);

  const openDropDownSearch = () => {
    setMenuOpen(!menuOpen);
  };

  const companySelected = (e) => {
    setCompany(e);
    setSearchQuery("");
    openDropDownSearch();

    console.log(e);
    getSquats(e.companyName);
    setEditorState(EditorState.createEmpty());
  };

  const getSquatAssistants = async (s) => {
    if (s.workingHours == null) {
      s.workingHours = {
        sunday: { isWorkingDay: false, from: "", to: "" },
        monday: { isWorkingDay: false, from: "", to: "" },
        tuesday: { isWorkingDay: false, from: "", to: "" },
        wednesday: { isWorkingDay: false, from: "", to: "" },
        thursday: { isWorkingDay: false, from: "", to: "" },
        friday: { isWorkingDay: false, from: "", to: "" },
        saturday: { isWorkingDay: false, from: "", to: "" },
      };
    }
    var assistants = await getApiSquatAssistants(s.id);
    setSquat({ ...s, assistants: assistants });
    setAssistant(null);
  };

  const selectSquatAssistants = (value) => {
    setAssistant(value);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromText(value.additionalSystemPrompt)
      )
    );
    setTool(null);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const setWorkingday = (day, value) => {
    var workingHours = { ...squat.workingHours };
    workingHours[day].isWorkingDay = value;
    if (workingHours[day].from == "") {
      workingHours[day].from = "08:00";
    }
    if (workingHours[day].to == "") {
      workingHours[day].to = "17:00";
    }
    setSquat({
      ...squat,
      workingHours: workingHours,
    });
  };

  const setTime = (type, day, value) => {
    var workingHours = { ...squat.workingHours };
    workingHours[day][type] = value ?? "";

    setSquat({
      ...squat,
      workingHours: workingHours,
    });
  };

  const updateAssistant = async () => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n")
      .replace(/\n\s*\n\s*\n/g, "\n\n");

    const newAssistant = { ...assistant, additionalSystemPrompt: value };
    setAssistant(newAssistant);
    setSquat({
      ...squat,
      assistants: squat.assistants.map((x) =>
        x.id == newAssistant.id ? newAssistant : x
      ),
    });
    setIndicatorState("Assistant updating.");
    await updateApiAssistant(newAssistant);

    resetIndicatorState("Assistant has been successfully updated.");
  };

  const deleteAssistant = async () => {
    if (
      (await confirm(
        "Are you sure you want to delete assistant: " + assistant.assistantName
      )) == true
    ) {
      setIndicatorState("Deleting assistant.");
      await deleteApiAssistant(assistant.id);

      setSquat({
        ...squat,
        assistants: squat.assistants.filter((x) => x.id != assistant.id),
      });
      setAssistant(null);

      let id = assistant.id;
      setTimeout(async () => {
        if (id == squat.squatLead) {
          await deleteSquatLead();
        }
      }, 1000);
      resetIndicatorState("Assistant has been successfully deleted.");
    }
  };

  const deleteSquat = async () => {
    if (
      (await confirm("Are you sure you want to delete squat: " + squat.name)) ==
      true
    ) {
      setIndicatorState("Deleting squat.");
      await deleteApiSquat(squat.id);
      setSquats([...squats.filter((x) => x.id != squat.id)]);
      setSquat({});
      setTool(null);
      resetIndicatorState("Squat has been successfully deleted.");
    } else {
    }
  };

  const addNewSquat = async () => {
    setIndicatorState("Creating new squat.");
    const newSquat = {
      name: name,
      id: makeid(20),
      conversationDetails: [],
      lastStepFields: [],
      personInformation: [],
      customer: company.companyName,
      workingHours: {
        sunday: { isWorkingDay: false, from: "", to: "" },
        monday: { isWorkingDay: false, from: "", to: "" },
        tuesday: { isWorkingDay: false, from: "", to: "" },
        wednesday: { isWorkingDay: false, from: "", to: "" },
        thursday: { isWorkingDay: false, from: "", to: "" },
        friday: { isWorkingDay: false, from: "", to: "" },
        saturday: { isWorkingDay: false, from: "", to: "" },
      },
      logging: company.companyName.toLowerCase() + "_" + toUnderscoreCase(name),
    };

    await addNewApiSquat(newSquat);
    setSquat({ ...newSquat, assistants: [] });
    setSquats([...squats, newSquat]);
    setOpenNewSquat(false);
    setName("");
    setTool(null);
    resetIndicatorState("Squat has been successfully created.");
  };

  const toUnderscoreCase = (str) => {
    // Split the string into words using a regex to handle multiple spaces and other whitespace characters
    let words = str.trim().split(/\s+/);

    // Capitalize the first letter of each word except the first word
    for (let i = 1; i < words.length; i++) {
      words[i] = words[i][0] + words[i].slice(1);
    }

    // Join the words back together
    return words.join("_").toLowerCase();
  };
  //const detachAssistant = () => {};

  const attachAssistant = async () => {
    setIndicatorState("Attaching assistant.");
    let data = {
      assistantId: assistant.id,
      name: squat.customer + "-" + squat.name,
    };
    var response = await attachApiAssistant(data);
    if (!response.isError) {
      resetIndicatorState("Assistant has been successfully attached.");
      var s = {
        ...squat,
        voximplantApplicationId: response.object.applicationId,
      };
      setSquat(s);

      delete s.assistants;
      await updateApiSquat(s);
    } else {
      resetIndicatorState("Attaching assistant failed.");
    }
  };

  const updateSquat = async () => {
    setIndicatorState("Squat updating.");
    var s = { ...squat };
    delete s.assistants;
    await updateApiSquat(s);
    resetIndicatorState("Squat has been successfully updated.");
  };

  const addNewAssistant = async () => {
    setIndicatorState("Creating assistant.");
    const id = makeid(20);
    const newAssistant = {
      assistantName: name,
      id: id,
      assistantId: id,
      tools: [],
      phonenumber: "",
      description: "",
      model: 3,
      squatId: squat.id,
      voice: "nl_NL_ColetteNeural",
      voiceSpeed: "0",
      additionalSystemPrompt:
        'retourneer elk bericht in het volgende json format: {Message:"", Event: NULL/HANDOVER/TRANSFER_TO_ASSISTANT, data:c# dictionary with key extension}',
    };
    await addNewApiAssistant(newAssistant);

    if (squat.assistants.length == 0) {
      await updateSquatLead(newAssistant.id);
      let updatedSquat = { ...squat };
      updatedSquat.squatLead = newAssistant.id;
      setSquat({
        ...updatedSquat,
        assistants: [...squat.assistants, newAssistant],
      });
      setSquats([
        ...squats.filter((x) => (x.id == updatedSquat.id ? updatedSquat : x)),
      ]);
      resetIndicatorState("Assistant has been successfully created.");
    } else {
      let updatedSquat = {
        ...squat,
        assistants: [...squat.assistants, newAssistant],
      };
      setSquat(updatedSquat);
      setSquats([
        ...squats.filter((x) => (x.id == updatedSquat.id ? updatedSquat : x)),
      ]);
      resetIndicatorState("Assistant has been successfully created.");

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(newAssistant.additionalSystemPrompt)
        )
      );
    }

    setAssistant(newAssistant);
    setOpenNewAssistant(false);
    setName("");
  };

  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const updateSquatLead = async (newSquatLead) => {
    setSquats([
      ...squats.map((x) =>
        x.id == squat.id ? x : { ...x, squatLead: newSquatLead }
      ),
    ]);
    setSquat({ ...squat, squatLead: newSquatLead });
    await updateApiSquat({
      ...squat,
      squatLead: newSquatLead,
    });
  };
  const updateName = async (name) => {
    setAssistant({ ...assistant, assistantName: name });
  };
  const updateDescription = async (description) => {
    setAssistant({ ...assistant, description: description });
  };
  const updateFirstMessage = async (message) => {
    setAssistant({ ...assistant, firstMessage: message });
  };
  const updateModel = async (model) => {
    setAssistant({ ...assistant, model: parseInt(model) });
  };
  const updateVoice = async (voice) => {
    setAssistant({ ...assistant, voice: voice });
  };
  const updateVoiceSpeed = async (voiceSpeed) => {
    setAssistant({ ...assistant, voiceSpeed: voiceSpeed });
  };

  const updateToolName = async (name) => {
    const t = { ...tool, name: name };
    setTool(t);
    setAssistant({
      ...assistant,
      tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
    });
  };

  const updateField = async (fieldId, name, value) => {
    const fields = [
      ...tool.fields.map((x) =>
        x.id == fieldId ? { ...x, [name]: value } : x
      ),
    ];

    const t = {
      ...tool,
      fields: fields,
    };

    setTool(t);
    setAssistant({
      ...assistant,
      tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
    });
  };

  const updateToolCategory = async (category) => {
    const t = { ...tool, category: category };
    setTool(t);
    setAssistant({
      ...assistant,
      tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
    });
  };
  const updateToolDescription = async (description) => {
    const t = { ...tool, description: description };
    setTool(t);
    setAssistant({
      ...assistant,
      tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
    });
  };
  const updateToolScope = async (scope) => {
    const t = { ...tool, scope: scope };
    setTool(t);
    setAssistant({
      ...assistant,
      tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
    });
  };

  const updateToolFillers = async (fillers) => {
    const t = { ...tool, fillers: fillers };
    setTool(t);
    setAssistant({
      ...assistant,
      tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
    });
  };

  const deleteSquatLead = async () => {
    await updateApiSquat({
      id: squat.id,
      name: squat.name,
      squatLead: null,
    });
    setSquats(await getApiSquats());
  };

  const deleteTool = async () => {
    if (
      (await confirm("Are you sure you want to delete tool: " + tool.name)) ==
      true
    ) {
      setAssistant({
        ...assistant,
        tools: assistant.tools.filter((x) => x.id != tool.id),
      });
      setTool(null);
    }
  };

  const addNewTool = () => {
    const newTool = {
      name: name,
      id: makeid(20),
      fields: [],
      description: "",
      fillers: "",
      scope: "global",
    };
    setTool(newTool);
    setAssistant({ ...assistant, tools: [...assistant.tools, newTool] });
    setOpenNewTool(false);
    setName("");
  };

  const addNewField = () => {
    const newField = {
      field: name,
      id: makeid(20),
      description: "",
      required: false,
      type: "string",
    };
    const t = {
      ...tool,
      fields: [...tool.fields, newField],
    };
    setTool(t);

    setAssistant({
      ...assistant,
      tools: [...assistant.tools.map((x) => (x.id == tool.id ? t : x))],
    });
    setOpenNewField(false);
    setName("");
  };

  const deleteField = async (field) => {
    if (
      (await confirm(
        "Are you sure you want to delete field: " + field.field
      )) == true
    ) {
      const t = {
        ...tool,
        fields: [...tool.fields.filter((x) => x.id != field.id)],
      };
      setTool(t);
      setAssistant({
        ...assistant,
        tools: assistant.tools.map((x) => (x.id == tool.id ? t : x)),
      });
    }
  };

  const addLoggingField = (field) => {
    setSquat({
      ...squat,
      [field]: [...squat[field], ""],
    });
  };

  const toggleKeepLogging = async (keepLogging) => {
    const update = { ...squat, keepLogging: keepLogging };
    setSquat(update);

    await updateApiSquat(update);
  };

  const [promptExample, setPromptExample] = useState("");
  const openSeePromptDialog = () => {
    getPromptExample({ assistantId: assistant.id }).then((x) => {
      setPromptExample(x.data.prompt);
      setOpenSeePrompt(true);
    });
  };

  const [copyAssistants, setCopyAssistants] = useState([]);
  const [copySquats, setCopySquats] = useState([]);
  const [openCopyFunctionDialog, setOpenCopyFunctionDialog] = useState(false);
  const [copyToCompanyObj, setCopyToCompanyObj] = useState();
  const [copyToCompany, setCopyToCompany] = useState("");
  const [copyToCompanyName, setCopyToCompanyName] = useState();
  const [copyToSquat, setCopyToSquat] = useState("");
  const [copyToAssistant, setCopyToAssistant] = useState("");
  const handleClickCloseCopyIntegrationCancel = () => {
    setOpenCopyFunctionDialog(false);
    setCopyToCompany("");
    setCopyToSquat("");
    setCopyToAssistant("");
  };
  const handleClickCloseCopyIntegrationOk = () => {
    setIndicatorState("Coping tool");
    getApiAssistant(copyToAssistant).then((x) => {
      var updatedAssistant = x[0];
      if (updatedAssistant.tools == null) {
        updatedAssistant.tools = [];
      }
      let newTool = { ...tool };
      newTool.name = copyToCompanyName;
      newTool.id = makeid(20);
      updatedAssistant.tools.push(newTool);
      updateApiAssistant(updatedAssistant);

      resetIndicatorState("Tool successfully copied");
    });

    setOpenCopyFunctionDialog(false);
    setCopyToCompany("");
    setCopyToSquat("");
    setCopyToAssistant("");
  };
  const copyCompanySelected = (e) => {
    setCopyToCompany(e.target.value);
    getquats(e.target.value);
    setCopyToAssistant("");
    setCopyToSquat("");
    setCopyAssistants([]);
  };

  const copySquatSelected = (e) => {
    setCopyToSquat(e.target.value);
    setCopyToAssistant("");
    getAssistants(e.target.value);
  };
  const copyAssistantSelected = (e) => {
    setCopyToAssistant(e.target.value);
  };
  const getquats = async (companyId) => {
    const comp = await getApiCompany(companyId);
    await getApiSquats(comp.name).then((x) => {
      setCopySquats(x);
      setCopyToAssistant("");
    });
  };

  const getAssistants = async (assistantId) => {
    await getApiSquatAssistants(assistantId).then((x) => {
      setCopyAssistants(x);
    });
  };

  const openDialogForCopyfunction = () => {
    setOpenCopyFunctionDialog(true);
    setCopyToCompanyName(tool.name);
  };
  return (
    <div className="w-full h-full">
      {assistant && <AssistantChatbox assistant={assistant} />}
      <div className="">
        <h1>Assistants</h1>
        <h3>Configure assistants</h3>
      </div>

      <div className="flex h-full gap-3">
        <div className="w-1/4">
          <div className={styles.optionsAgentSelection}>
            <div className={styles.optionsHeader}>
              <MdOutlineManageAccounts className={styles.optionsIcon} /> Select
              company
            </div>
            <div className={styles.dropdown}>
              <button onClick={openDropDownSearch} className={styles.dropbtn}>
                {company?.name}{" "}
                <SlArrowDown
                  className={`${styles.dropdownIcon} ${
                    menuOpen ? styles.rotate : null
                  }`}
                />
              </button>
              <div
                id={styles.myDropdown}
                className={`${styles["dropdown-content"]} ${
                  menuOpen ? styles.show : null
                }`}
              >
                <input
                  value={searchQuery}
                  type="text"
                  placeholder="Zoeken"
                  id={styles.myInput}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {filteredCompanies.map((s) => {
                  return (
                    <span key={s.name} onClick={() => companySelected(s)}>
                      {s.name}
                    </span>
                  );
                })}
                {filteredCompanies.length === 0 ? (
                  <span className={styles.noResult}>Geen resultaten</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <h1>Squats</h1>
            <div className="ml-auto flex gap-3 text-3xl">
              {company && (
                <MdAdd
                  className="cursor-pointer"
                  onClick={(e) => setOpenNewSquat(true)}
                />
              )}
              {squat?.id && (
                <MdOutlineDelete
                  onClick={deleteSquat}
                  className="cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex flex-column gap-1 py-3">
            {squats
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((s) => {
                return (
                  <div
                    key={s.id}
                    className={`cursor-pointer  text-2xl bg-slate-800 text-[#eee] p-3 hover:bg-slate-700 ${
                      s.id == squat.id ? "!bg-slate-700" : null
                    }`}
                    onClick={() => getSquatAssistants(s)}
                  >
                    {s.name}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-1/4">
          <div className="flex items-center">
            <h1>Assistants</h1>
            <div className="ml-auto flex gap-3 text-3xl">
              {squat?.id && (
                <MdAdd
                  className="cursor-pointer"
                  onClick={(e) => setOpenNewAssistant(true)}
                />
              )}
              {assistant && (
                <MdOutlineDelete
                  onClick={deleteAssistant}
                  className="cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex flex-column gap-1  py-3">
            {squat?.assistants
              ?.sort((a, b) => (a.assistantName > b.assistantName ? 1 : -1))
              ?.map((ass) => {
                return (
                  <div
                    key={ass.id}
                    className={`cursor-pointer flex flex-col bg-slate-800 text-[#eee] p-3 hover:bg-slate-700 ${
                      assistant?.id == ass.id ? "!bg-slate-700" : null
                    }`}
                    onClick={() => selectSquatAssistants(ass)}
                  >
                    <div className="text-2xl flex gap-2">
                      {ass.assistantName}
                      {squat.squatLead == ass.id ? <FaStar /> : null}
                    </div>
                    <div>{ass.description}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-2/4 overflow-y-scroll h-[95%]">
          {assistant && (
            <div>
              <div className="flex items-center">
                <h1>{assistant.assistantName}</h1>
                <div className="ml-auto flex gap-3 text-3xl">
                  <IoSaveOutline
                    onClick={updateAssistant}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <form class="">
                <div className="flex w-full gap-4">
                  <div class="">
                    <label for="name" class="block mb-2 text-lg text-gray-900 ">
                      Name
                    </label>
                    <input
                      type="text"
                      value={assistant.assistantName}
                      onChange={(e) => updateName(e.target.value)}
                      id="name"
                      class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                    />
                  </div>
                  <div class="flex flex-1 gap-1">
                    <div className="flex-1">
                      <label
                        for="description"
                        class="block mb-2 text-lg text-gray-900 "
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        value={assistant?.description ?? ""}
                        onChange={(e) => updateDescription(e.target.value)}
                        id="description"
                        class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                      />
                    </div>
                    <div className="flex-2">
                      <label
                        for="voice"
                        class="block mb-2 text-lg text-gray-900 "
                      >
                        Voice
                      </label>
                      <select
                        value={assistant.voice}
                        onChange={(e) => updateVoice(e.target.value)}
                        defaultValue="nl_NL_ColetteNeural"
                        id="voice"
                        class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                      >
                        <option value="microsoft:nl_NL_ColetteNeural">
                          Microsoft: Colette
                        </option>
                        <option value="microsoft:nl_NL_FennaNeural">
                          Microsoft: Fenna
                        </option>
                        <option value="microsoft:nl_NL_MaartenNeural">
                          Microsoft: Maarten
                        </option>
                        <option value="google:nl_NL_Wavenet_A">
                          Google: Inge
                        </option>
                        <option value="google:nl_NL_Wavenet_B">
                          Google: Geert
                        </option>
                        <option value="google:nl_NL_Wavenet_C">
                          Google: Ronald
                        </option>
                        <option value="google:nl_NL_Wavenet_D">
                          Google: Sandra
                        </option>
                      </select>
                    </div>
                    <div className="flex-2">
                      <label
                        for="voiceSpeed"
                        class="block mb-2 text-lg text-gray-900 "
                      >
                        Voice speed
                      </label>
                      <select
                        value={assistant.voiceSpeed}
                        onChange={(e) => updateVoiceSpeed(e.target.value)}
                        defaultValue="nl_NL_ColetteNeural"
                        id="voiceSpeed"
                        class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                      >
                        {[...Array(21).keys()].map((x) => (
                          <option key={x} value={x}>
                            +{x}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex w-full gap-4">
                  <div class="w-full">
                    <label for="name" class="block mb-2 text-lg text-gray-900 ">
                      First message
                    </label>
                    <input
                      type="text"
                      value={assistant.firstMessage ?? ""}
                      onChange={(e) => updateFirstMessage(e.target.value)}
                      id="id"
                      class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                    />
                  </div>
                </div>
                <div className="flex w-full gap-4">
                  <div class="">
                    <label for="name" class="block mb-2 text-lg text-gray-900 ">
                      Id
                    </label>
                    <input
                      type="text"
                      value={assistant.assistantId}
                      id="id"
                      disabled
                      class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                    />
                  </div>

                  <div class="">
                    <label
                      for="model"
                      class="block mb-2 text-lg text-gray-900 "
                    >
                      Model
                    </label>
                    <select
                      value={assistant.model}
                      onChange={(e) => updateModel(e.target.value)}
                      defaultValue={3}
                      id="model"
                      class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                    >
                      <option value={4}>gpt4-o</option>
                      <option value={3}>gpt4-o-mini</option>
                    </select>
                  </div>
                  <div class="">
                    <label
                      for="squatLead"
                      class="block mb-2 text-lg text-gray-900 "
                    >
                      SquatLead
                    </label>
                    <select
                      value={squat.squatLead}
                      onChange={(e) => updateSquatLead(e.target.value)}
                      defaultValue="3"
                      id="squatLead"
                      class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                    >
                      {squat.assistants.map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.assistantName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-auto ml-auto mr-3">
                    <div
                      className="flex p-2 px-3 bg-slate-800  text-white cursor-pointer"
                      onClick={openSeePromptDialog}
                    >
                      See Prompt
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 w-full pt-2">
                  <div
                    className={`p-2 border-x-2 border-t-2 border-slate-800 cursor-pointer ${
                      tabState == "prompt" ? "bg-slate-800 text-white" : ""
                    }`}
                    onClick={() => setTabState("prompt")}
                  >
                    Prompt
                  </div>
                  <div
                    className={`p-2 border-x-2 border-t-2 border-slate-800 cursor-pointer ${
                      tabState == "functions" ? "bg-slate-800 text-white" : ""
                    }`}
                    onClick={() => setTabState("functions")}
                  >
                    Functions
                  </div>
                  <div
                    className={`p-2 border-x-2 border-t-2 border-slate-800 cursor-pointer ${
                      tabState == "logging" ? "bg-slate-800 text-white" : ""
                    }`}
                    onClick={() => setTabState("logging")}
                  >
                    Logging (based on squad)
                  </div>
                </div>
                <div className="flex w-full gap-4">
                  <div class="flex-1">
                    {tabState == "prompt" && (
                      <Editor
                        toolbarHidden
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="bg-slate-800 !h-[500px] !text-[#eee] p-2"
                        onEditorStateChange={onEditorStateChange}
                      />
                    )}
                    {tabState == "functions" && (
                      <div>
                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800">
                          {assistant.tools.map((x) => (
                            <div
                              key={x.id}
                              className={`p-2 border-2 border-slate-800 cursor-pointer ${
                                tool == x ? "bg-slate-800 text-white" : ""
                              }`}
                              onClick={() => setTool(x)}
                            >
                              {x.name}
                            </div>
                          ))}
                          <div className="ml-auto flex gap-3 text-3xl">
                            <MdAdd
                              className="cursor-pointer"
                              onClick={(e) => setOpenNewTool(true)}
                            />
                            {tool && (
                              <>
                                <MdOutlineDelete
                                  onClick={deleteTool}
                                  className="cursor-pointer"
                                />

                                <IoMdCopy
                                  onClick={openDialogForCopyfunction}
                                  className="cursor-pointer"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {tool && (
                          <div className="border-2 p-2 mt-2">
                            <div>
                              let op: category is verplicht. (naam en categorie
                              zijn hoofdletter gevoelig)
                            </div>
                            <div class="flex flex-1 gap-1">
                              <div className="flex-2">
                                <label
                                  for="name"
                                  class="block mb-2 text-lg text-gray-900 "
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  value={tool?.name}
                                  onChange={(e) =>
                                    updateToolName(e.target.value)
                                  }
                                  id="name"
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              </div>
                              <div className="flex-1">
                                <label
                                  for="description"
                                  class="block mb-2 text-lg text-gray-900 "
                                >
                                  Description
                                </label>
                                <input
                                  type="text"
                                  value={tool?.description ?? ""}
                                  onChange={(e) =>
                                    updateToolDescription(e.target.value)
                                  }
                                  id="description"
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              </div>
                              <div className="">
                                <label
                                  for="scope"
                                  class="block mb-2 text-lg text-gray-900 "
                                >
                                  Scope
                                </label>
                                <select
                                  value={tool?.scope}
                                  onChange={(e) =>
                                    updateToolScope(e.target.value)
                                  }
                                  defaultValue="global"
                                  id="type"
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                >
                                  <option value="global">Global</option>
                                  <option value="company">Company</option>
                                </select>
                              </div>
                              <div className="">
                                <label
                                  for="category"
                                  class="block mb-2 text-lg text-gray-900 "
                                >
                                  Category
                                </label>
                                <input
                                  type="text"
                                  value={tool?.category ?? ""}
                                  onChange={(e) =>
                                    updateToolCategory(e.target.value)
                                  }
                                  id="category"
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              </div>
                            </div>
                            <div class="flex flex-1 gap-1">
                              <div className="flex-1">
                                <label
                                  for="fillers"
                                  class="block mb-2 text-lg text-gray-900 "
                                >
                                  Filler words ; seperated
                                </label>
                                <input
                                  type="text"
                                  value={tool?.fillers ?? ""}
                                  onChange={(e) =>
                                    updateToolFillers(e.target.value)
                                  }
                                  id="fillers"
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              </div>
                            </div>
                            <div className="mt-2 flex gap-2 flex-col">
                              <div className="flex">
                                <h3>Fields:</h3>
                                <div className="ml-auto flex gap-3 text-3xl">
                                  <MdAdd
                                    className="cursor-pointer"
                                    onClick={(e) => setOpenNewField(true)}
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col gap-4 overflow-y-scroll h-[360px]">
                                {tool.fields?.map((field) => {
                                  return (
                                    <div
                                      className="p-2 bg-slate-800"
                                      kye={field.id}
                                    >
                                      <div class="flex flex-1 gap-1 item">
                                        <div className="flex-2">
                                          <label
                                            for="name"
                                            class="block mb-2 text-lg text-white"
                                          >
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            value={field?.field}
                                            onChange={(e) =>
                                              updateField(
                                                field?.id,
                                                "field",
                                                e.target.value
                                              )
                                            }
                                            id="field"
                                            class="flex-1 rounded-none  p-2 text-slate-800 bg-white"
                                          />
                                        </div>
                                        <div className="flex-2">
                                          <label
                                            for="name"
                                            class="block mb-2 text-lg text-white"
                                          >
                                            Required
                                          </label>
                                          <select
                                            value={field?.required}
                                            onChange={(e) =>
                                              updateField(
                                                field?.id,
                                                "required",
                                                e.target.value
                                              )
                                            }
                                            defaultValue="string"
                                            id="type"
                                            class="flex-1 rounded-none p-2 text-slate-800 bg-white"
                                          >
                                            <option value={true}>
                                              Required
                                            </option>
                                            <option value={false}>
                                              Not required
                                            </option>
                                          </select>
                                        </div>
                                        <div className="flex-1">
                                          <div className="flex justify-between items-center">
                                            <label
                                              for="description"
                                              class="block mb-2 text-lg text-white"
                                            >
                                              Type
                                            </label>
                                            <MdOutlineDelete
                                              onClick={() => deleteField(field)}
                                              className="cursor-pointer text-white text-2xl"
                                            />
                                          </div>

                                          <select
                                            value={field?.type}
                                            onChange={(e) =>
                                              updateField(
                                                field?.id,
                                                "type",
                                                e.target.value
                                              )
                                            }
                                            defaultValue="string"
                                            id="type"
                                            class="flex-1 rounded-none p-2 text-slate-800 bg-white"
                                          >
                                            <option value="string">
                                              String
                                            </option>
                                            <option value="boolean">
                                              Boolean
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="flex flex-1 gap-1">
                                        <div className="flex-1">
                                          <label
                                            for="description"
                                            class="block mb-2 text-lg text-white"
                                          >
                                            Description
                                          </label>
                                          <textarea
                                            type="text"
                                            value={field?.description ?? ""}
                                            onChange={(e) =>
                                              updateField(
                                                field?.id,
                                                "description",
                                                e.target.value
                                              )
                                            }
                                            id="description"
                                            class="flex-1 rounded-none p-2 text-slate-800 w-full focus:outline-none bg-white"
                                          >
                                            {field?.description ?? ""}
                                          </textarea>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {tabState == "logging" && (
                      <div>
                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Logging Table
                            </label>
                            <div className="ml-auto flex gap-3 text-3xl">
                              <IoSaveOutline
                                onClick={updateSquat}
                                className="cursor-pointer"
                              />
                              {/* {squat.voximplantApplicationId == null && (
                                <InsertLinkIcon
                                  onClick={attachAssistant}
                                  className="cursor-pointer"
                                />
                              )} */}
                              {/* {squat.voximplantApplicationId != null && (
                                <InsertLinkIcon
                                  onClick={detachAssistant}
                                  className="cursor-pointer"
                                />
                              )} */}
                            </div>
                          </div>

                          <input
                            type="text"
                            value={squat.logging ?? ""}
                            id="logging"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                logging: toUnderscoreCase(e.target.value),
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>
                        <div>
                          <FormGroup style={flexContainer}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    toggleKeepLogging(e.target.checked)
                                  }
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                  }}
                                  checked={squat.keepLogging}
                                />
                              }
                              label={
                                <Box component="div" fontSize={12}>
                                  Keep logging
                                </Box>
                              }
                            />
                          </FormGroup>
                        </div>

                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Squat ID
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.id}
                            id="squatId"
                            readOnly
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>

                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Teams Organisation ID
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.teamsOrganisationId}
                            id="teamsOrganisationId"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                teamsOrganisationId: e.target.value,
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>

                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Teams ID
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.teamsId}
                            id="teamsId"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                teamsId: e.target.value,
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>

                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Whatsapp ID
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.whatsappPhoneId}
                            id="whatsappPhoneId"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                whatsappPhoneId: e.target.value,
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>
                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Whatsapp Token
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.whatsappPhoneToken}
                            id="whatsappPhoneToken"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                whatsappPhoneToken: e.target.value,
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>

                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Zendesk App ID
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.zendeskAppId}
                            id="zendeskAppId"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                zendeskAppId: e.target.value,
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>

                        <div className="flex gap-2 pt-2 border-t-2 border-slate-800  flex-col">
                          <div className="flex items-center">
                            <label
                              for="logging"
                              class="block mb-2 text-lg text-gray-900 "
                            >
                              Zendesk Token
                            </label>
                          </div>

                          <input
                            type="text"
                            value={squat.zendeskToken}
                            id="zendeskToken"
                            onChange={(e) =>
                              setSquat({
                                ...squat,
                                zendeskToken: e.target.value,
                              })
                            }
                            class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                          />
                        </div>

                        <div className="">
                          <div
                            className={styles.workingHoursConfiguratorHeader}
                          >
                            Working hours
                          </div>
                          <div className={styles.workingHoursConfigurator}>
                            {Object.entries(squat?.workingHours)
                              .sort(
                                (a, b) =>
                                  days.indexOf(a[0]) - days.indexOf(b[0])
                              )
                              .map((entry) => {
                                const [key, value] = entry;
                                return (
                                  <div
                                    key={key}
                                    className={styles.workingHoursRow}
                                  >
                                    <div className={styles.dayLabel}>{key}</div>
                                    <div className={styles.isWorkingDay}>
                                      <FormSelect
                                        value="Open"
                                        isSelected={value.isWorkingDay}
                                        handleChange={(e) =>
                                          setWorkingday(key, e)
                                        }
                                      />
                                    </div>
                                    <div className={styles.workingDayFrom}>
                                      {value.isWorkingDay && (
                                        <div className={styles.workingHoursRow}>
                                          <div className={styles.workingLabel}>
                                            from:
                                          </div>
                                          <TimePicker
                                            locale="nl-NL"
                                            disableClock={true}
                                            onChange={(e) =>
                                              setTime("from", key, e)
                                            }
                                            value={value.from}
                                            format={"HH:mm"}
                                            maxDetail="minute"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className={styles.workingDayTo}>
                                      {value.isWorkingDay && (
                                        <div className={styles.workingHoursRow}>
                                          <div className={styles.workingLabel}>
                                            to:
                                          </div>
                                          <TimePicker
                                            locale="nl-NL"
                                            disableClock={true}
                                            onChange={(e) =>
                                              setTime("to", key, e)
                                            }
                                            value={value.to}
                                            format={"HH:mm"}
                                            maxDetail="minute"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between items-center">
                            <h2>Conversation details</h2>
                            <div className="ml-auto flex gap-3 text-3xl">
                              <MdAdd
                                className="cursor-pointer"
                                onClick={(e) =>
                                  addLoggingField("conversationDetails")
                                }
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 flex-col pl-6">
                            {squat.conversationDetails?.map((convDetail, i) => {
                              return (
                                <input
                                  key={i}
                                  type="text"
                                  value={convDetail ?? ""}
                                  onChange={(e) =>
                                    setSquat({
                                      ...squat,
                                      conversationDetails:
                                        squat.conversationDetails.map((x, u) =>
                                          u == i ? e.target.value : x
                                        ),
                                    })
                                  }
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="">
                          <div className="flex justify-between items-center">
                            <h2>Person information</h2>
                            <div className="ml-auto flex gap-3 text-3xl">
                              <MdAdd
                                className="cursor-pointer"
                                onClick={(e) =>
                                  addLoggingField("personInformation")
                                }
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 flex-col pl-6">
                            {squat.personInformation?.map((personInfo, i) => {
                              return (
                                <input
                                  key={i}
                                  type="text"
                                  value={personInfo ?? ""}
                                  onChange={(e) =>
                                    setSquat({
                                      ...squat,
                                      personInformation:
                                        squat.personInformation.map((x, u) =>
                                          u == i ? e.target.value : x
                                        ),
                                    })
                                  }
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between items-center">
                            <h2>Last step fields</h2>
                            <div className="ml-auto flex gap-3 text-3xl">
                              <MdAdd
                                className="cursor-pointer"
                                onClick={(e) =>
                                  addLoggingField("lastStepFields")
                                }
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 flex-col pl-6">
                            {squat.lastStepFields?.map((step, i) => {
                              return (
                                <input
                                  key={i}
                                  type="text"
                                  value={step ?? ""}
                                  onChange={(e) =>
                                    setSquat({
                                      ...squat,
                                      lastStepFields: squat.lastStepFields.map(
                                        (x, u) => (u == i ? e.target.value : x)
                                      ),
                                    })
                                  }
                                  class="flex-1 rounded-none bg-slate-800 p-2 text-[#eee]"
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openNewSquat}
        onClose={() => setOpenNewSquat(false)}
      >
        <DialogTitle>New squat</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Squat name"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewSquat(false)}>Cancel</Button>
          <Button onClick={addNewSquat}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openNewAssistant}
        onClose={() => setOpenNewAssistant(false)}
      >
        <DialogTitle>New assistant for squat {squat.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Assistant name"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewAssistant(false)}>Cancel</Button>
          <Button onClick={addNewAssistant}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openNewTool}
        onClose={() => setOpenNewTool(false)}
      >
        <DialogTitle>
          New tool for assistant {assistant?.assistantName}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tool name"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewTool(false)}>Cancel</Button>
          <Button onClick={addNewTool}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openNewField}
        onClose={() => setOpenNewField(false)}
      >
        <DialogTitle>New field for tool {tool?.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Field name"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewField(false)}>Cancel</Button>
          <Button onClick={addNewField}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={openSeePrompt}
        onClose={() => setOpenSeePrompt(false)}
      >
        <DialogContent>
          <textarea className="w-full h-[80vh] p-2 border-none outline-none">
            {promptExample}
          </textarea>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSeePrompt(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCopyFunctionDialog} fullWidth={"lg"}>
        <DialogTitle>Copy Function</DialogTitle>
        <DialogContent>
          <div className={styles.conditionRowContainer}>
            <div className={styles.conditionRow}>
              <strong>Company: </strong>
              <select
                onChange={(e) => copyCompanySelected(e)}
                value={copyToCompany}
              >
                <option></option>
                {companies?.map((s) => {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <hr />
            <div className={styles.conditionRow}>
              <strong>Squat: </strong>
              <select
                onChange={(e) => copySquatSelected(e)}
                value={copyToSquat}
              >
                <option></option>
                {copySquats?.map((s) => {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <hr />
            <div className={styles.conditionRow}>
              <strong>Assistant: </strong>
              <select
                onChange={(e) => copyAssistantSelected(e)}
                value={copyToAssistant}
              >
                <option></option>
                {copyAssistants?.map((s) => {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.assistantName}
                    </option>
                  );
                })}
              </select>
            </div>
            <hr />
            <div className={styles.conditionRow}>
              <strong>Name: </strong>
              <TextField
                className={styles.conditionInput}
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                onChange={(e) => setCopyToCompanyName(e.target.value)}
                value={copyToCompanyName}
                variant="standard"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseCopyIntegrationCancel}>
            Cancel
          </Button>
          <Button onClick={handleClickCloseCopyIntegrationOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Assistants;
