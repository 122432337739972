const testIntegration = async (webhookRequest) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(webhookRequest),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_WEBHOOK_URL + "/api/webhook/TestIntegration",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const testWebhook = async (webhookRequest) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(webhookRequest),
  };
  return await fetch(
    //process.env.REACT_APP_BRAIN_WEBHOOK_URL + "/api/webhook/TestWebhook",
    process.env.REACT_APP_BRAIN_WEBHOOK_URL + "/api/webhook/TestWebhook",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export { testIntegration, testWebhook };
